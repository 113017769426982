import React from 'react';
import '../Dialog/Dialog.css'
import Loading from '../Loading/Loading';
import { loadingColors } from '../Loading/Loading';

const LoadingDialog = ({id, txt}) => {
    const content = <Loading
        id="dialog-loading"
    />
    //console.log(txt);
    //const size = txt == undefined ? {width: "100px", heigth: "100px"} : {};
    return (
        <div className='dialog' id={id}>
            <div className='dialog-box loading-dialog'>
               <Loading id="dialog-loading" color={loadingColors["white"]}/> 
               {txt != undefined && <span className='dialog-txt' style={{marginLeft: 20 + "px"}}>{txt}</span>}
            </div>
        </div>
    )
}

export default LoadingDialog;