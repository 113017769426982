import React, { useEffect } from 'react';
import './Title.css';

const Title = ({txt}) => {
    //<img src={titleBg} className='title-bg'></img>
    useEffect(() =>{
        //imposta il margin dei titoli
        const screenWidth = window.innerWidth;
        const newMargin = screenWidth <= 500 ? (600*50)/screenWidth + "px" : '50px';
        const title = document.querySelector(".title");
        if(title) {
            title.style.marginTop = newMargin;
        }
    }, []);
    
    return (
        <div className='title'>
            <span className='title-txt'>{txt}</span>
        </div>
    );
}

export default Title;