import React from 'react';
import './CommonButton.css';

export const btnColors = {
    "orange" : "common-btn-orange",
    "blue" : "common-btn-blue",
    "green" : "common-btn-green",
    "grey" : "common-btn-grey",
    "red" : "common-btn-red"
}

export const btnSizes = {
    "default" : "common-btn-default",
    "default-wide" : "common-btn-default-wide",
    "mini" : "common-btn-mini",
    "mini2" : "common-btn-mini-2",
    "choice1" : "common-btn-choice-1",
    "choice2" : "common-btn-choice-2",
    "button" : "common-btn-popup",
    "button-multi" : "common-btn-popup-multi",
    "wide" : "common-btn-wide"
}

const CommonButton = ({id, txt, color, size, click, enabled=true}) => {
    let cl = "";
    if(enabled) {
        cl = "common-btn " + color + " " + size;
    } else {
        cl = "common-btn " + btnColors["grey"] + " " + size;
    }
    
    return (
        <div className={cl} id={id} onClick={click}>
            <span className={size == btnSizes['button-multi'] ? "common-btn-txt-multi" : "common-btn-txt"}>
                {txt.length>50 ? (<img src={txt} alt="icona" className='common-btn-icon' />) : (txt)}
                </span>
        </div>
    );
}

export default CommonButton;