import React, { useEffect, useRef, useState } from 'react';

const TimerComponent = ({ onTimerEnd, onTimeUpdate, initialTime }) => {
  const [timer, setTimer] = useState({
    active: true,
    timeLeft: initialTime
  });
  const timerId = useRef(null);

  useEffect(() => {
    if (timer.active && timer.timeLeft > 0) {
      timerId.current = setInterval(() => {
        setTimer(prevTimer => {
          const newTimeLeft = prevTimer.timeLeft - 1;
          onTimeUpdate(newTimeLeft); // Aggiorna il componente padre con il nuovo tempo rimasto
          return { ...prevTimer, timeLeft: newTimeLeft };
        });
      }, 1000);
    } else if (timer.timeLeft === 0) {
      clearInterval(timerId.current);
      onTimerEnd(); // Notifica al componente padre che il timer è terminato
    }

    // Pulizia dell'intervallo quando il componente viene smontato o il timer non è più attivo
    return () => {
      if (timerId.current) {
        clearInterval(timerId.current);
      }
    };
  }, [timer, onTimerEnd, onTimeUpdate]);

  return (
    <div></div>
  );
};

export default TimerComponent;
