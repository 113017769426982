import { initializeApp } from 'firebase/app';
import { globalVars } from './App';
import { getDatabase, ref, get, set, onValue, push, remove, onDisconnect, onChildAdded, goOffline, goOnline } from 'firebase/database';
import { firebaseCommands } from './Screens/Game/Game';


const firebaseConfig = {
    apiKey: "AIzaSyC1nvzYqmB7RrarkxHTiwVOa_0VyLVAszc",
    authDomain: "where-is-c203f.firebaseapp.com",
    databaseURL: "https://where-is-c203f.firebaseio.com",
    projectId: "where-is-c203f",
    storageBucket: "where-is-c203f.appspot.com",
    messagingSenderId: "162977474877",
    appId: "1:162977474877:web:0bcef528a95641c180bd54"
};
  
const app = initializeApp(firebaseConfig);
const database = getDatabase(app);

/** Ottiene un valore dal database dato il percorso
 * Intesavincente è implicito
 */
export const getValue = async (path, raw=false) => {
    path = "intesavincente/"+path;
    const snapshot = await get(ref(database, path));
    if(raw) {
        return snapshot;
    }
    return snapshot.val();
}

/** Imposta un valore nel database dato il percorso
 * Intesavincente è implicito
 */
export const setValue = async (path, value) => {
    path = "intesavincente/"+path;
    return await set(ref(database, path), value).then(() => {
        return "ok";
    }).catch((e) => {
        console.error("ERRORE "+ e);
        return e;
    });
}

/** Appende un valore nel database dato il percorso.
 * Intesavincente è implicito
 */
export const pushValue = async (path, value, shouldRemoveOnDisconnect = false) => {
    path = "intesavincente/" + path;
    const newPathRef = ref(database, path);
    try {
        await push(newPathRef, value);
        if (shouldRemoveOnDisconnect) {
            onDisconnect(newPathRef).remove();
        }
        return "ok";
    } catch (e) {
        console.error("ERRORE " + e);
        return e;
    }
};


/** Controlla se il percorso passato esiste nel database.
 * Intesavincente è implicito
 */
export const keyExists = async (path) => {
    path = "intesavincente/"+path;
    const snapshot = await get(ref(database, path));
    
    return snapshot.exists();
};

/** In un percorso con elementi pushed restituisce la key che contiene
 * la coppia key-value passata
 * Intesavincente è implicito
 */
export const getKey = async (path, key, value) => {
    path = "intesavincente/"+path;
    const snapshot = await get(ref(database, path));
    let realKey = null;

    snapshot.forEach((e) => {
        let val = e.val();
        //console.log(val[key]);
        if(val[key] == value) {
            //esiste una chiave nel percorso con il valore indicato
            //console.log(e.key);
            realKey = e.key;
            return;
        }
    });

    return realKey;
}

/** Aggiunge un listener al percorso passato.
 * Restituisce un callback (parametro) e il listener stesso per disconnetterlo
 * Intesavincente + globalVars.code/ è implicito
 */
export const addPathListener = async (path, callback) => {
    path = "intesavincente/"+ globalVars.code+ "/"+ path;

    const listener = onValue(ref(database, path), (snapshot) => {
        callback(snapshot.val());
    });
    //usato per il detatch
    return listener;
}

/** Aggiunge un onDisconnectListener al percorso.
 * Se il percorso non viene specificato, controlla se il client è un display o un bottone
 * e aggiunge il listener al percorso relativo di default.
 * Intesavincente è implicito
 */
export const addPresence = async (path=null) => {
    if(path==null) {
        if(globalVars.type == "b") {
            path = "intesavincente/"+ globalVars.code+ "/button";
        }
        if(globalVars.type == "d") {
            path = "intesavincente/"+ globalVars.code+ "/display";
        }
    } else {
        path = "intesavincente/" + path;
    }
    
    onDisconnect(ref(database, path)).remove(ref(database, path));
}


/** Avvisa il chiamante quando il client corrente si disconnette chiamando
 * la funzione callback
 */
export const onClientDisconnected = async (callback) => {
    const connectedRef = ref(database, '.info/connected');
    
    // Aggiungi un listener per rilevare la disconnessione del client
    return onValue(connectedRef, (snapshot) => {
      const isConnected = snapshot.val();
      
      if (isConnected === false) {
        //console.log("client disconnected");
        callback();
      }
    });
}

/** Avvisa il chiamante quando il client opposto si disconnette chiamando
 * la funzione callback
 */
export const onPeerDisconnected = async (callback) => {
    let path;
    if(globalVars.type == "b") {
        path = "intesavincente/"+ globalVars.code+ "/display";
    }
    if(globalVars.type == "d") {
        path = "intesavincente/"+ globalVars.code+ "/button";
    }

    return onValue(ref(database, path), (snapshot) => {
        if (!snapshot.exists()) {
            callback();
        }
    });
}

/** RIimuove un percorso dal database
 * Intesavincente è implicito
 */
export const removePath = async (path) => {
    path = "intesavincente/"+path;
    return await remove(ref(database, path)).then(() => {
        return "ok";
    }).catch((e) => {
        console.error("ERRORE "+ e);
        return e;
    });
}

/** Aggiunge un listener alla lista dei comandi ed esegue il comando quando lo riceve
 */
export const addNewCommandsListener = async () => {
    const path = "intesavincente/"+globalVars.code+"/commands";
    const dbRef = ref(database, path);

    // Listener for child added
    const listener = onChildAdded(dbRef, (snapshot) => {
        const data = snapshot.val();
        //console.log(data);
        //console.log("NOT FIRST "+ data);
        if((data.sentBy == globalVars.type && data.executeSelf == true) || data.sentBy != globalVars.type) {
            //scorre tra tutti i comandi e confronta il nome
            Object.entries(firebaseCommands).forEach(([key, c]) => {
                if(c.name == data.commandName) {
                    //console.log("comando trovato");
                    if(data.commandName == "showButton" && data.status) {
                        c.call(firebaseCommands.reciver, data.status);
                        return;
                    }
                    if(data.commandName == "showResults" && data.wait) {
                        c.call(firebaseCommands.reciver, true, data.wait);
                        return;
                    }
                    if(data.commandName == "startVideo" && typeof data.isLast === "boolean") {
                        c.call(firebaseCommands.reciver, data.isLast);
                        return;
                    }
                    c.call(firebaseCommands.reciver);
                    return;
                }
            })
        }
    });

    // Return the listener so it can be removed later
    return listener;
}


export const disconnect = () => {
    goOffline(database);
}

export const connect = () => {
    goOnline(database);
}

export default app;
