import React, { useEffect, useState, useRef } from 'react';
import '../Game.css';
import Title from '../../../Objects/Title/Title';
import List, { listTypes } from '../../../Objects/List/List';
import CommonButton, { btnColors, btnSizes } from '../../../Objects/CommonButton/CommonButton';
import Input, { inputColors, inputSizes } from '../../../Objects/Input/Input';
import { getKey, pushValue, removePath, setValue } from '../../../firebase';
import { errcode, fullScreen, globalVars } from '../../../App';
import LoadingDialog from '../../../Objects/LoadingDialog/LoadingDialog';
import Dialog, { dialogColors } from '../../../Objects/Dialog/Dialog';
import { firebaseCommands, listHasTeam } from '../Game';

import deleteIcon from '../../../assets/images/delete_icon.png'
import doubleIncon from '../../../assets/images/double_icon.png'
import unchecked from "../../../assets/images/unchecked_icon.png"
import checked from "../../../assets/images/checked_icon.png"


const CreateTeams = ({listData, changeState, useStandards, setUseStandards, useCustoms, setUseCustoms, customWords, setCustomWords, percent, setPercent}) => {
    const [isCreating, create] = useState({
        step: 1,
        index: -1,
        next: 1
    });

    const [canConfirm, setCanConfirm] = useState(false);
    const [canStart, setCanStart] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");

    const [myTeams, setMyTeams] = useState([]);
    const [showMyTeams, setShowMyTeams] = useState(false);

    const [showCustomWords, setShowCustomWords] = useState(false);
    const [showGroup, setShowGroup] = useState(false);
    const [curGroup, setCurGroup] = useState(0);
    const [showAddGroup, setShowAddGroup] = useState(false);
    const [showAddWord, setShowAddWord] = useState(false);
    const [canConfirmGroup, setCanConfirmGroup] = useState(false);
    const [firstLoad, setFirstLoad] = useState(true);
    const [isDouble, setIsDouble] = useState(false);
    const [stdPerc, setStdPerc] = useState("100%");
    const [persPerc, setPersPerc] = useState("0%");

    /* struttura custom words:
    [gruppo {
        name,
        parole [
            parola {
                name,
                isDouble,
                selected,
                enabled     vero se tra le custom words attive non è già presente
            },
        ],
        selected,
        enabled     vero solo se c'è almeno una parola al suo interno
    },]
    */

    const checkIcons = {
        "check_false": unchecked,
        "check_true": checked,
    }

    const canConfirmRef = useRef(canConfirm);
    const canConfirmGroupRef = useRef(canConfirmGroup);
    const isDoubleRef = useRef(isDouble);
    
    useEffect(() => {
        canConfirmRef.current = canConfirm;
    }, [canConfirm])

    useEffect(() => {
        canConfirmGroupRef.current = canConfirmGroup;
    }, [canConfirmGroup])

    useEffect(() => {
        isDoubleRef.current = isDouble;
    }, [isDouble])

    useEffect(() => {
        //console.log("changed "+ isCreating.step);
        const steps = ["", "#create-team-view", "#create-team-create", "#create-team-edit", "#myTeams-dialog"]
        if(isCreating.step != isCreating.next) {
            let step1 = document.querySelector(steps[isCreating.step]);
            step1.classList.remove("fade-in");
            step1.classList.add("fade-out");
            setTimeout(() => {
                create(prevState => ({
                    ...prevState,
                    step: isCreating.next
                }));
            }, 300);
        }
        if(isCreating.step == isCreating.next) {
            //lastStep = isCreating.step;
            setTimeout(() => {
                let step2 = document.querySelector(steps[isCreating.step]);
                step2.style.opacity = null;
                step2.classList.add("fade-in");
            }, 10);
        }
    }, [isCreating])

    useEffect(() => {
        if(showMyTeams) {
            setTimeout(() => {
                let d = document.querySelector("#myTeams-dialog");
                d.style.opacity = null;
                d.classList.add("fade-in");
            }, 10);
        }
        
    }, [showMyTeams])

    useEffect(() => {
        if(showCustomWords) {
            setTimeout(() => {
                let d = document.querySelector("#customWords-dialog");
                d.style.opacity = null;
                d.classList.add("fade-in");
            }, 10);
        }
        
    }, [showCustomWords])

    useEffect(() => {
        if(showAddGroup) {
            setTimeout(() => {
                let d = document.querySelector("#addGroup-dialog");
                d.style.opacity = null;
                d.classList.add("fade-in");
            }, 10);
        } else {
            setCanConfirmGroup(false);
        }
    }, [showAddGroup])
    
    useEffect(() => {
        if(showGroup) {
            setTimeout(() => {
                let d = document.querySelector("#wordGroup-dialog");
                d.style.opacity = null;
                d.classList.add("fade-in");
            }, 10);
        }
        
    }, [showGroup])

    useEffect(() => {
        if(showAddWord) {
            setTimeout(() => {
                let d = document.querySelector("#addWord-dialog");
                d.style.opacity = null;
                d.classList.add("fade-in");
            }, 10);
        } else {
            setCanConfirmGroup(false);
        }
    }, [showAddWord])

    useEffect(() => {
        if(!firstLoad) {
            let list = JSON.parse(JSON.stringify(customWords));;

            list.forEach(group => {
                group.words.forEach(word => {
                    delete word.selected;
                })
                delete group.selected;
                delete group.enabled;
            });

            //console.log(list);
            if(canUseCustoms()) {
                setUseCustoms(true);
            } else {
                setUseStandards(true);
            }
            setUseCustoms(canUseCustoms());
            localStorage.setItem("customWords", JSON.stringify(list));
        }
    }, [customWords])

    useEffect(() => {
        if(useStandards && useCustoms) {
            setPercent(0.7);
        } else {
            setPercent(useStandards ? 1 : 0);
        }
    }, [useStandards, useCustoms])

    useEffect(() => {
        const slider =document.getElementById("percent-slider");

        if(slider) {
            let value = parseFloat(slider.value).toFixed(1);
            setStdPerc(parseInt(value * 100) + "%");
            setPersPerc(parseInt((1 - value).toFixed(1) * 100) + "%")
        }
        
    }, [percent])

    useEffect(() => {
        if(error!="") {
            setTimeout(() => {
                let d = document.querySelector("#error");
                d.style.opacity = null;
                d.classList.add("fade-in");
            }, 10);
            
        }
    }, [error])

    useEffect(() => {
        //console.log(localStorage.getItem("customWords"));
        let t = JSON.parse(localStorage.getItem("myTeams"));
        let l = [];

        t.forEach(e => {
            l.push({
                name: e,
                enabled: true,
                selected: false
            })
        });
        setMyTeams(l);

        if(customWords.length == 0) {
            let a = JSON.parse(localStorage.getItem("customWords"));
            a.forEach(e => {
                e.words.forEach(ee => {
                    ee.selected = false;
                });
                e.selected = false;
                e.enabled = (e.words.length > 0)
            });
            setFirstLoad(false);
            setCustomWords(a);
        }
        
    }, [])
    
    if(listData.length > 0) {
        if(!canStart) {
            setCanStart(true);
        }
    } else {
        if(canStart) {
            setCanStart(false);
        }
    }

    const onInputChange = (event, charLimit, enable) => {
        let txt = event.target.value.trim();
        if(txt !="" && txt.length <= charLimit) {
            enable(true);
        } else {
            enable(false);
        }

        if(enable == setCanConfirmGroup) {
            if(txt.trim().split(" ").length > 1) {
                setIsDouble(true);
            } else {
                setIsDouble(false);
            }
        }
    }

    const closePopup = (id, hide) => {
        let d = document.getElementById(id);
        d.classList.remove("fade-in")
        d.style.opacity = null;
        d.classList.add("fade-out");

        setTimeout(() => {
            hide(false);
        }, 100);
    }

    /*
        GESTIONE CREAZIONE SQUADRE
    */
    const createTeam = async () => {
        if(canConfirmRef.current) {
            const name = document.getElementById("teamName").querySelector(".input").value.toUpperCase().trim();

            if(!listHasTeam(listData, name)) {
                //creazione nuova squadra
                const newTeam = {
                    name: name,
                    score: 0
                }
                listData.push(newTeam);
                //console.log(listData.elements);

                changeState(listData);

                setLoading(true);
                await setValue(globalVars.code+"/teams/", listData)
                .then(() => {
                    setLoading(false);
                    create(prevState => ({
                        ...prevState,
                        next: 1,
                    }));
                    setCanConfirm(false);
                }).catch((e) => {
                    setLoading(false);
                    setError(errcode["ERR_EDIT_TEAM"]);
                    //console.log(e);
                });
            } else {
                setError(errcode["ERR_DUPL_TEAM"]);
            }
        }
    }

    const editTeam = async () => {
        if(canConfirmRef.current) {
            const name = document.getElementById("teamName").querySelector(".input").value.toUpperCase().trim();

            if(!listHasTeam(listData, name) || listData.indexOf(name) == isCreating.index) {
                //modifica squadra
                //let key = await getKey(globalVars.code+"/teams", "name", listData.elements[isCreating.index]);
                
                listData[isCreating.index].name = name;
                changeState(listData);
                //console.log(listData.elements);
                setLoading(true);

                await setValue(globalVars.code+"/teams/", listData)
                .then(() => {
                    setLoading(false);
                    create(prevState => ({
                        ...prevState,
                        next: 1,
                    }));
                    setCanConfirm(false);
                }).catch((e) => {
                    setLoading(false);
                    setError(errcode["ERR_EDIT_TEAM"]);
                    //console.log(e);
                });
            } else {
                setError(errcode["ERR_DUPL_TEAM"]);
            }
        }
    }

    const deleteTeam = async () => {

        //elimina squadra
        //let key = await getKey(globalVars.code+"/teams", "name", listData.elements[isCreating.index]);
        
        listData.splice(isCreating.index, 1);

        changeState(listData);
        
        //console.log(listData.elements);
        setLoading(true);

        await setValue(globalVars.code+"/teams/", listData)
        .then(() => {
            setLoading(false);
            create(prevState => ({
                ...prevState,
                next: 1,
            }));
            setCanConfirm(false);
        }).catch((e) => {
            setLoading(false);
            setError(errcode["ERR_EDIT_TEAM"]);
            //console.log(e);
        });
    }

    const addGroup = () => {
        if(canConfirmGroupRef.current) {
            const name = document.getElementById("groupName").querySelector(".input").value.toUpperCase().trim();
            if(!listHasTeam(customWords, name)) {
                closePopup("addGroup-dialog", setShowAddGroup);

                let list = customWords.slice();
                list.push({
                    name,
                    selected: false,
                    enabled: false,
                    words: []
                })

                setCustomWords(list);
            } else {
                setError(errcode["ERR_DUPL_GROUP"]);
            }
        }
    }

    const addWord = (setCanConfirmGroup) => {
        let isDouble = isDoubleRef.current
        //console.log(curGroup, isDouble);
        if(canConfirmGroupRef.current) {
            const input = document.getElementById("newWord");
            const name = input.querySelector(".input").value.toUpperCase().trim();
            if(!listHasTeam(customWords[curGroup].words, name)) {
                //closePopup("addWord-dialog", setShowAddWord);
                setCanConfirmGroup(false);
                
                let list = customWords.slice();
                list[curGroup].words.push({
                    name,
                    isDouble,
                    selected: true
                })
                list[curGroup].enabled = true;
                list[curGroup].selected = isGroupSelected(list[curGroup]);

                /* if(isDouble) {
                    doubleWords.push(name);
                    setDoubleWords(doubleWords);
                } else {
                    words.push(name);
                    setWords(words);
                } */

                //aggiungi le custom words all'avvio della partita

                setCustomWords(list);

                input.querySelector(".input").value = "";
                input.querySelector(".input").focus();
                input.querySelector(".input").placeholder = "Parola aggiunta!"

                let wt = 80;

                input.classList.toggle(inputColors['light-blue'])
                input.classList.toggle(inputColors.done);
                setTimeout(() => {
                    input.classList.toggle(inputColors['light-blue'])
                    input.classList.toggle(inputColors.done);
                    setTimeout(() => {
                        input.classList.toggle(inputColors['light-blue'])
                        input.classList.toggle(inputColors.done);
                        setTimeout(() => {
                            input.classList.toggle(inputColors['light-blue'])
                            input.classList.toggle(inputColors.done);
                        }, wt);
                    }, wt);
                }, wt);
            } else {
                setError(errcode["ERR_DUPL_WORD"]);
            }
        }

        setIsDouble(false);
    }

    const isGroupSelected = (group) => {
        if(group.words.length > 0) {
            let full = true;
            let indt = false;
            group.words.forEach(e => {
                if(e.selected) {
                    indt = true;
                }
                if(!e.selected) {
                    full = false;
                }
            });
            if(full) {
                return true;
            }
            if(indt) {
                return "indt";
            }
        }
        return false;
    }

    const canUseCustoms = () => {
        return customWords.some(group => 
            group.words.some(word => word.selected)
        );
    }

    const updateSliderVal = (id, _setValue) => {
        const slider =document.getElementById(id);
        let value = parseFloat(slider.value).toFixed(1);

        if(!useCustoms || !useStandards) {
            value = useStandards ? 1 : 0;
        }
        _setValue(value);

    }

    return (
        <div className='createTeams'>
            {/* loading dialog */}
            {loading && <LoadingDialog id="creatingTeam" txt="Salvataggio..."/>}

            {/* error dialog */}
            {error && <Dialog
                id="error"
                title="Si è verificato un errore!"
                txt={error}
                common={() => {
                    closePopup("error", setError);
                }}
            />}


            {/* Visualizza squadre */}
            {isCreating.step==1 && <div className='createTeams' id="create-team-view" style={{opacity: 0}}>

                {showCustomWords && <Dialog
                    id="customWords-dialog"
                    title="Gruppi di parole"
                    txt={<div id='groups-dialog'>
                        <span id='customWords-dialog-txt'>Seleziona gruppi di parole oppure<br></br>scegli le singole parole al loro interno.</span>
                        <List
                            id="customWords-list"
                            listData={customWords}
                            fixed={true}
                            interactable={true}
                            type={listTypes.wordGroups}
                            height="350px"
                            onItemClick={(index) => {
                                //espandi il gruppo
                                closePopup("customWords-dialog", setShowCustomWords);
                                setCurGroup(index);
                                setShowGroup(true);
                            }}
                            onItemDelete={(index) => {
                                //elimina gruppo
                                let list = customWords.slice();
                                list.splice(index, 1);
                                setCustomWords(list);
                            }}
                            onChecked={(index) => {
                                //seleziona/deseleziona il gruppo
                                let list = customWords.slice();
                                list[index].selected = !list[index].selected;
                                list[index].words.forEach(e => {
                                    e.selected = list[index].selected;
                                });
                                setCustomWords(list);
                            }}
                            placeHolder="Nessun gruppo di parole"
                        ></List>

                        <div id='custWords-options-container' style={{lineHeight: "20px", margin: "10px 0px"}}>
                            <span id='customWords-dialog-txt'>Parole utilizzate in partita:</span>

                            <div className='check-container' style={{marginTop: "0px"}}>
                                <div className='check-container' onClick={() => {
                                    if((useStandards && useCustoms) || !useStandards) {
                                        setUseStandards(!useStandards);
                                    }
                                }}>
                                    <img src={checkIcons["check_"+useStandards]} alt="check" className='list-icon' style={{marginRight: "10px"}}/>
                                    <span className='dialog-txt' style={{lineHeight: "30px"}}>Standard</span>
                                </div>

                                <div style={{width: "25px"}}></div>
                                <div className='check-container' onClick={() => {
                                    if((useStandards && useCustoms) || (!useCustoms && canUseCustoms())) {
                                        setUseCustoms(!useCustoms);
                                    }
                                }}>
                                    <img src={checkIcons["check_"+useCustoms]} alt="check" className='list-icon' style={{marginRight: "10px"}}/>
                                    <span className='dialog-txt' style={{lineHeight: "30px"}}>Personaliz.</span>
                                </div>
                            </div>

                            <div className='slider-container' style={{marginTop: "20px"}}>
                                <span id='std-perc' className='dialog-txt' style={{lineHeight: "30px", marginRight: "20px"}}>{stdPerc}</span>
                                <input
                                    id='percent-slider'
                                    type="range"
                                    min="0"
                                    max="1"
                                    step="0.1"
                                    value={percent}
                                    onChange={() => {
                                        updateSliderVal("percent-slider", setPercent);
                                    }}
                                    className="custom-slider"
                                />
                                <span id='pers-perc' className='dialog-txt' style={{lineHeight: "30px", marginLeft: "20px"}}>{persPerc}</span>
                            </div>
                        </div>

                        <CommonButton
                            id="addGroup"
                            txt="Crea gruppo"
                            color={btnColors["green"]}
                            size={btnSizes["wide"]}
                            click={() => {
                                setShowAddGroup(true);
                            }}
                        />
                    </div>}
                    common={() => {
                        closePopup("customWords-dialog", setShowCustomWords);
                    }}
                    color={dialogColors.color1}
                ></Dialog>}

                {showAddGroup && <Dialog
                    id="addGroup-dialog"
                    title="Crea un nuovo gruppo"
                    height='350px'
                    txt={<div id='groups-dialog'>
                        <span className='dialog-txt' style={{marginBottom: "10px"}}>Dai un nome al gruppo:</span>
                        <Input
                            id="groupName"
                            type="text"
                            size={inputSizes["default"]}
                            color={inputColors["light-blue"]}
                            placeholder="Nome gruppo"
                            onChange={(event) => {
                                onInputChange(event, 13, setCanConfirmGroup);
                            }}
                            confirm={addGroup}
                        ></Input>
                        <div style={{height: "40px"}}></div>
                    </div>}
                    cancel={() => {
                        closePopup("addGroup-dialog", setShowAddGroup);
                    }}
                    confirm={addGroup}
                    canConfirm={canConfirmGroup}
                ></Dialog>}

                {showGroup && <Dialog
                    id="wordGroup-dialog"
                    title={"Parole di "+customWords[curGroup].name}
                    txt={<div id='groups-dialog'>
                        <div style={{lineHeight: "20px"}}>
                            <img src={doubleIncon} alt="double_icon" className='list-icon' style={{marginRight: "15px"}}/>
                            <span id='customWords-dialog-txt'>indica che la parola<br></br>sarà usata per il raddoppio.</span>
                        </div>
                        
                        <List
                            id="customWords-list"
                            listData={customWords[curGroup].words}
                            fixed={true}
                            interactable={true}
                            type={listTypes.customWords}
                            height="450px"
                            onItemClick={(index) => {
                                //seleziona/deseleziona la parola
                                let list = customWords.slice();
                                list[curGroup].words[index].selected = !list[curGroup].words[index].selected;
                                list[curGroup].selected = isGroupSelected(list[curGroup]);
                                setCustomWords(list);
                            }}
                            onItemDelete={(index) => {
                                //elimina parola
                                let list = customWords.slice();
                                list[curGroup].words.splice(index, 1);
                                list[curGroup].enabled = list[curGroup].words.length > 0
                                list[curGroup].selected = isGroupSelected(list[curGroup]);
                                setCustomWords(list);
                            }}
                            placeHolder="Nessuna parola in questo gruppo"
                        ></List>
                        <CommonButton
                            id="addWord"
                            txt="Aggiungi parole"
                            color={btnColors["green"]}
                            size={btnSizes["wide"]}
                            click={() => {
                                //mostra popup per aggiungere la parola
                                setIsDouble(false);
                                setShowAddWord(true);
                            }}
                        />
                    </div>}
                    cancel={() => {
                        closePopup("wordGroup-dialog", setShowGroup);
                        setShowCustomWords(true);                        
                    }}
                    canConfirm={true}
                    color={dialogColors.color1}
                ></Dialog>}

                {showAddWord && <Dialog
                    id="addWord-dialog"
                    title="Aggiungi nuove parole"
                    height='320px'
                    txt={<div id='groups-dialog'>
                        <span className='dialog-txt' style={{marginBottom: "10px"}}>Inserisci una singola parola da indovinare:</span>
                        <Input
                            id="newWord"
                            type="text"
                            size={inputSizes["default"]}
                            color={inputColors["light-blue"]}
                            placeholder="Parola"
                            onChange={(event) => {
                                onInputChange(event, 17, setCanConfirmGroup);
                            }}
                            confirm={() => {
                                addWord(setCanConfirmGroup);
                            }}
                        ></Input>
                        
                        <div className='check-container' onClick={() => {
                                setIsDouble(!isDouble);
                        }}>
                            <img src={checkIcons["check_"+isDouble]} alt="check" className='list-icon' style={{marginRight: "15px"}}/>
                            <span className='dialog-txt' style={{lineHeight: "30px"}}>Raddoppio?</span>
                        </div>
                        <span id='customWords-dialog-txt' style={{marginTop: "10px", opacity: "0.8"}}>Le parole per il raddoppio devono contenere due parole!</span>
                        <div style={{height: "40px"}}></div>

                    </div>}

                    cancel={() => {
                        closePopup("addWord-dialog", setShowAddWord);
                    }}
                    confirm={() => {
                        addWord(setCanConfirmGroup);
                    }}
                    canConfirm={canConfirmGroup}
                ></Dialog>}

                <Title txt="Crea le squadre" />
                <div className='createTeams' style={{marginTop: 20+"px"}}>
                    <List id="teams"
                        listData={listData}
                        fixed={false}
                        interactable={true}
                        onItemClick={(index) => {
                            create(prevState => ({
                                ...prevState,
                                next: 3,
                                index: index
                            }));
                        }}
                    />
                </div>
                
                <CommonButton
                    id="addTeam"
                    txt="Aggiungi squadra"
                    color={btnColors["blue"]}
                    size={btnSizes["wide"]}
                    click={() => {
                        create(prevState => ({
                            ...prevState,
                            next: 2,
                            index: listData.count
                        }));
                    }}
                />
                <CommonButton
                    id="customWords"
                    txt="Parole personaliz."
                    color={btnColors["green"]}
                    size={btnSizes["wide"]}
                    click={() => {
                        setShowCustomWords(true);
                    }}
                />
                <CommonButton
                    id="play"
                    txt="Inizia!"
                    color={canStart ? btnColors["orange"] : btnColors["grey"]}
                    size={btnSizes["default"]}
                    click={() => {
                        if(canStart) {
                            setLoading(true);
                            //aggiungi le squadre create a myTeams
                            let list = [];
                            myTeams.forEach(e => {
                                list.push(e.name);
                            });
                            
                            listData.forEach(e => {
                                if(!listHasTeam(myTeams, e.name)) {
                                    list.push(e.name);
                                }
                            });

                            localStorage.setItem("myTeams", JSON.stringify(list));

                            //verifica quali parole devono essere utilizzate
                            //salva nel database le eventuali parole personalizzate in uso

                            /* //console.log(words);
                            //console.log(doubleWords); */
                            let w_list = [];
                            let double_list = [];
                            let w_forceStd = false;
                            let dw_forceStd = false;
                            if(useCustoms) {
                                customWords.forEach(group => {
                                    group.words.forEach(word => {
                                        if(!word.isDouble && word.selected) {
                                            w_list.push(word.name);  
                                        }
                                    });
                                });
                                //se le standard sono già incluse
                                //il force cambia solo la probabilità
                                //con cui verranno pescate le custom
                                if(w_list.length < 5) {
                                    w_forceStd = true;
                                }

                                customWords.forEach(group => {
                                    group.words.forEach(word => {
                                        if(word.isDouble && word.selected) {
                                            double_list.push(word.name);  
                                        }
                                    });
                                });
                                if(double_list.length < 2) {
                                    dw_forceStd = true;
                                }
                            }

                            /* //console.log(words);
                            //console.log(doubleWords); */

                            setCanStart(false);

                            (async () => {
                                try {
                                  await Promise.all([
                                    setValue(globalVars.code + "/useStandards", useStandards),
                                    setValue(globalVars.code + "/useCustoms", useCustoms),
                                    setValue(globalVars.code + "/w_forceStd", w_forceStd),
                                    setValue(globalVars.code + "/dw_forceStd", dw_forceStd),
                                    setValue(globalVars.code + "/percent", percent),
                                    useCustoms ? setValue(globalVars.code + "/words", JSON.stringify(w_list)) : Promise.resolve(),
                                    useCustoms ? setValue(globalVars.code + "/doubleWords", JSON.stringify(double_list)) : Promise.resolve()
                                  ]);
                              
                                  firebaseCommands.startGame.call(firebaseCommands.creator);
                                  setLoading(false);
                                } catch (errore) {
                                  setError(errcode["ERR_START_GAME"])
                                }
                              })();                              
                        }
                    }}
                />
            </div>}
            
            {/* Crea una squadra */}
            {isCreating.step==2 && <div className='createTeams' id="create-team-create" style={{opacity: 0}}>

                {showMyTeams && <Dialog
                    id="myTeams-dialog"
                    title="Le mie squadre"
                    height='780px'
                    txt={
                        <List
                            id="myTeams-list"
                            listData={myTeams}
                            fixed={true}
                            interactable={true}
                            type={listTypes.myTeams}
                            height="600px"
                            onItemClick={(index) => {
                                //seleziona/deseleziona
                                let list = myTeams.slice();
                                list[index].selected = !list[index].selected
                                setMyTeams(list);
                                
                            }}
                            onItemDelete={(index) => {
                                //elimina squadra
                                let list = myTeams.slice();
                                list.splice(index, 1);
                                setMyTeams(list);
                            }}
                        ></List>
                    }
                    confirm={async () => {
                        closePopup("myTeams-dialog", setShowMyTeams);
                        //aggiungi le squadre nel database
                        let hasChanged = false;
                        myTeams.forEach(e => {
                            if(!listHasTeam(listData, e.name) && e.selected) {
                                listData.push({
                                    name: e.name,
                                    score: 0
                                });
                                hasChanged = true;
                            }
                            //console.log(e.name, listHasTeam(listData, e.name), e.selected, e.enabled);
                            if(listHasTeam(listData, e.name) && !e.selected && e.enabled) {
                                listData.splice(index(e.name), 1);
                                hasChanged = true;
                            }
                        });
                        
                        //console.log(listData);

                        if(hasChanged) {
                            changeState(listData);

                            setLoading(true);
                            await setValue(globalVars.code+"/teams/", listData)
                            .then(() => {
                                setLoading(false);
                                create(prevState => ({
                                    ...prevState,
                                    next: 1,
                                }));
                                setCanConfirm(false);
                            }).catch((e) => {
                                setLoading(false);
                                setError(errcode["ERR_EDIT_TEAM"]);
                                //console.log(e);
                            });
                        }
                        
                        function index(name) {
                            listData.forEach((e, i) => {
                                if(e.name == name) {
                                    return i;
                                }
                            });
                        }
                    }}
                    color={dialogColors.color1}
                    canConfirm={true}
                ></Dialog>}

                <Title txt="Nuova squadra:" />
                <div style={{display: 'flex', flexGrow: 1}}></div>
                <span id="name-txt">Dai un nome alla tua squadra:</span>
                <span id="hint-txt">(dovrebbe includere un articolo)</span>
                <Input
                    id="teamName"
                    type="text"
                    size={inputSizes["default"]}
                    color={inputColors["default"]}
                    placeholder="Nome squadra"
                    onChange={(event) => {
                        onInputChange(event, 13, setCanConfirm);
                    }}
                    confirm={createTeam}
                />
                
                <CommonButton
                    id="myTeams"
                    txt="Le mie squadre"
                    color={btnColors["green"]}
                    size={btnSizes["wide"]}
                    click={() => {
                        //controlla tra le squadre aggiunte manualmente
                        //se ce n'è qualcuna salvata
                        let list = myTeams.slice();

                        list.forEach(e => {
                            e.enabled = !listHasTeam(listData, e.name) || e.selected;
                            e.selected = listHasTeam(listData, e.name) && e.enabled;
                        });

                        setShowMyTeams(true);
                    }}
                />
                <div style={{display: 'flex', flexGrow: 1}}></div>
                <CommonButton
                    id="saveTeam"
                    txt="Salva"
                    color={canConfirm ? btnColors["orange"] : btnColors["grey"]}
                    size={btnSizes["default"]}
                    click={createTeam}
                />
                <CommonButton
                    id="cancelTeam"
                    txt="Annulla"
                    color={btnColors["blue"]}
                    size={btnSizes["button"]}
                    click={() => {
                        setCanConfirm(false);
                        create(prevState => ({
                            ...prevState,
                            next: 1,
                        }));
                    }}
                />
                
            </div>}

            {/* Modifica una squadra */}
            {isCreating.step==3 && <div className='createTeams' id="create-team-edit" style={{opacity: 0}}>
                <Title txt="Modifica squadra:" />
                <div style={{display: 'flex', flexGrow: 1}}></div>
                <span id="name-txt">Nuovo nome:</span>
                <span id="hint-txt">(dovrebbe includere un articolo)</span>
                <Input
                    id="teamName"
                    type="text"
                    value={listData[isCreating.index] ? listData[isCreating.index].name : ""}
                    size={inputSizes["default"]}
                    color={inputColors["default"]}
                    placeholder="Nome squadra"
                    onChange={(event) => {
                        onInputChange(event, 13, setCanConfirm);
                    }}
                    confirm={editTeam}
                />
                
                <div style={{display: 'flex', flexGrow: 1}}></div>
                <div className='dialog-btns'>
                    <CommonButton
                        id="deleteTeam"
                        txt={deleteIcon}
                        color={btnColors["red"]}
                        size={btnSizes["mini2"]}
                        click={deleteTeam}
                    />
                    <div style={{width: 20+"px"}}></div>
                    <CommonButton
                        id="saveTeam"
                        txt="Salva"
                        color={canConfirm ? btnColors["orange"] : btnColors["grey"]}
                        size={btnSizes["default"]}
                        click={editTeam}
                    />
                </div>
                <div style={{height: 10+"px"}}></div>
                <CommonButton
                    id="cancelTeam"
                    txt="Annulla"
                    color={btnColors["blue"]}
                    size={btnSizes["button"]}
                    click={() => {
                        setCanConfirm(false);
                        create(prevState => ({
                            ...prevState,
                            next: 1,
                        }));
                    }}
                />
                
            </div>}


        </div>
    );
}

export default CreateTeams;