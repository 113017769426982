import React, { forwardRef, useEffect } from 'react';

const AudioComponent = forwardRef((props, ref) => {

    const handleTimeUpdate = () => {
        if (props.onTimeChange) {
            props.onTimeChange(ref.current.currentTime);
        }
    };

    useEffect(() => {
        if (ref.current) {
            //console.log(props.volume);
            ref.current.volume = props.volume;
        }
    }, [props.volume, ref]);

    return (
        <audio ref={ref} onTimeUpdate={handleTimeUpdate} preload='auto'>
            <source src={props.path} type="audio/mpeg" />
            Il tuo browser non supporta il tag audio.
        </audio>
    );
});

export default AudioComponent;
