import React, { useEffect, useState, useRef } from 'react';
import './Home.css'
import { useParams, useNavigate } from 'react-router-dom';
//images
import settingsIcon from '../../assets/images/settings_icon.png'
import fullscreenIcon from '../../assets/images/fullscree_icon.png'
//objs
import AppLogo from '../../Objects/AppLogo/appLogo';
import CommonButton, {btnColors, btnSizes} from '../../Objects/CommonButton/CommonButton';
import Title from '../../Objects/Title/Title';
import Loading, { loadingColors, loadingSpeeds } from '../../Objects/Loading/Loading';
import Input, { inputColors, inputSizes } from '../../Objects/Input/Input';
import Dialog from '../../Objects/Dialog/Dialog';
import { getValue, setValue, keyExists, addPathListener, addPresence } from '../../firebase';
import { errcode, globalVars, scaleElements, fullScreen } from '../../App';
import LoadingDialog from '../../Objects/LoadingDialog/LoadingDialog';
import QRGenerator from '../../Objects/QRGenerator/QRGenerator';
import SettingsDialog from '../../Objects/SettingsDialog';
import PrivacyPolicyLink from '../../Objects/PrivacyPolicyLink';
import CookiePolicyLink from '../../Objects/CookiePolicyLink';
import GuideDialog from '../../Objects/GuideDialog';




const Home = ({settings, showSettings, options, upadting}) => {
    const [step, setStep] = useState(1);
    const [error, setError] = useState("");
    const [btnEnabled, setBtnEnabled] = useState(true);
    const [isParamValid, setParamValid] = useState(true);
    const [canConfirm, setCanConfirm] = useState(false);
    const canConfirmRef = useRef(canConfirm);
    let { paramCode } = useParams();
    const navigate = useNavigate();

    //CONTROLLA SE ESISTE UN PARAMETRO E SE È UN CODICE PARTITA VALIDO
    //console.log(paramCode);

    if(!upadting) {
        if(typeof paramCode === "string" && paramCode.trim() !="" && paramCode.length == 6 && isParamValid) {
            //valido
            paramCode = paramCode.toUpperCase();
            joinGame(paramCode).then(() => {
                globalVars.code = paramCode;
                globalVars.type = "b";
                navigate("/game");
            }).catch((e) => {
                //console.log(e);
                setParamValid(false);
            })
        } else {
            //non valido
            paramCode = null;
            if(isParamValid) {
                setParamValid(false);
            }
        }
    }
    

    function fadeStep(s) {           //CAMBIA FASE DELLA SCHERMATA HOME
        //console.log(s);
        s = s==null ? step : s;
        //console.log("first "+ s);
        let step1 = document.querySelector(".home-step"+s);
        step1.classList.remove("fade-in");
        step1.classList.add("fade-out");
        setTimeout(() => {
            setStep(s+1);
        }, 300);
    }

    useEffect(() => {
        canConfirmRef.current = canConfirm;
    }, [canConfirm])

    useEffect(() => {
        scaleElements();
        document.querySelector(".App").classList.remove("stage");
        showSettings(false);
        /* (async () => {
            await tsParticles.load({
              id: "tsparticles",
              options: {
                background: {
                  color: "#000"
                },
                particles: {
                  shape: {
                    type: "emoji",
                    options: {
                      emoji: {
                        value: ["🟨", "🟥", "🟧", "🟪", "🟦", "🟩"]
                      }
                    }
                  },
                  life: {
                    duration: {
                      value: 0
                    }
                  },
                  number: {
                    value: 200,
                    max: 0,
                    density: {
                      enable: true
                    }
                  },
                  move: {
                    gravity: {
                      enable: false
                    },
                    decay: 0,
                    direction: "bottom",
                    speed: 5,
                    outModes: "out"
                  },
                  size: {
                    value: 13
                  },
                  opacity: {
                    value: 1,
                    animation: {
                      enable: false
                    }
                  },
                  rotate: {
                    value: {
                      min: 0,
                      max: 360
                    },
                    direction: "random",
                    move: true,
                    animation: {
                      enable: true,
                      speed: 60
                    }
                  },
                  tilt: {
                    direction: "random",
                    enable: true,
                    move: true,
                    value: {
                      min: 0,
                      max: 360
                    },
                    animation: {
                      enable: true,
                      speed: 60
                    }
                  },
                  roll: {
                    darken: {
                      enable: true,
                      value: 30
                    },
                    enlighten: {
                      enable: true,
                      value: 30
                    },
                    enable: true,
                    mode: "both",
                    speed: {
                      min: 15,
                      max: 25
                    }
                  },
                  wobble: {
                    distance: 30,
                    enable: true,
                    move: true,
                    speed: {
                      min: -15,
                      max: 15
                    }
                  }
                },
                
                emitters: [],
                preset: "confetti"
              }
            });
          })(); */
        
        let iubendaFloat = document.querySelector(".iubenda-tp-btn");
        if(iubendaFloat) {
            iubendaFloat.style.pointerEvents = "auto"
            iubendaFloat.style.opacity = "1";
        }
        
    }, []);

    //QUANDO CAMBIA LA VARIABILE STEP
    //CREAZIONE STANZA
    useEffect(() => {
        //console.log("second "+ step);
        setTimeout(() => {
            let step2 = document.querySelector(".home-step"+step);
            step2.style.opacity = null;
            step2.classList.add("fade-in");
            
            scaleElements();
            
        }, 10);
        
        if(step == 3 && globalVars.type=="d") {
            createGame();
        }
        
        async function createGame() {
            //await new Promise((resolve) => setTimeout(resolve, 1000));
            globalVars.code = await generateCode();
            const value = {
                "display": "online",
                "status": 0,
                "newRoom": true,
                "music": localStorage.getItem("music"),
                "effects": localStorage.getItem("effects")
            };
            const res = await setValue(globalVars.code, value);
            //console.log("res: "+res);
            if(res=="ok") {
                await addPresence(globalVars.code);
                //stanza creata
                //console.log("completato "+ globalVars.code);
                fadeStep(null);
                let stop = await addPathListener("button", (val) => {
                    if(val == "online") {
                        stop();
                        //console.log("pulsante connesso DISPLAY");
                        //vai al gioco come display (prende il tipo da globalVars)
                        navigate("/game");
                    }
                });
            } else {
                //errore
                console.error("ERROR 3");
                //console.log(res);
                setError(errcode["ERR_CREATE_GAME"]);
            }
        }
    }, [step]);

    //QUANDO CAMBIA LA VARIABILE ERROR
    useEffect(() => {
        if(error!="") {
            setTimeout(() => {
                let d = document.querySelector("#error");
                d.style.opacity = null;
                d.classList.add("fade-in");
            }, 10);
            
        }
    }, [error])
    
    const onInputChange = (event) => {
        if(event.target.value.length==6) {
            setCanConfirm(true);
        } else {
            setCanConfirm(false);
        }
    }
    

    const selectDisplay = () => {   //DISPLAY
        globalVars.type = "d";
        fadeStep(null);
    }

    const selectButton = () => {    //BUTTON
        globalVars.type = "b";
        fadeStep(null);
    }

    const closePopup = () => {      //CHIUDE MESSAGGIO DI ERRORE
        let d = document.querySelector("#error");
        d.classList.remove("fade-in")
        d.style.opacity = null;
        d.classList.add("fade-out");
        if(globalVars.type=="d") {
            setStep(2);
        }

        setTimeout(() => {
            setError("");
        }, 100);
    }

    const checkCode = async () => { //CONTROLLA IL CODICE INSERITO E ENTRA IN PARTITA
        let code = document.querySelector("#code").querySelector(".input").value.toUpperCase();
        let input = document.querySelector("#code");
        input.classList.remove(inputColors["error"]);

        setBtnEnabled(false);

        if(code.trim() !="" && code.length==6) {
            joinGame(code).then(() => {
                //quando è entrato nel gioco
                //vai al gioco come bottone (prende il tipo da globalVars)
                //console.log("pulsante connesso BUTTON");
                globalVars.code = code;
                navigate("/game");
            }).catch((e) => {
                //console.log(e);
                setError(errcode[e]);
                setBtnEnabled(true);
            })
        } else {
            input.classList.add(inputColors["error"]);
            setBtnEnabled(true);
        }

    }

    return (
        <div className='home'>
            {/* ERROR POPUP */}
            {error!="" && <Dialog
                id="error"
                title="Si è verificato un errore!"
                txt={error}
                common={closePopup}
            />}

            <SettingsDialog
                settings={settings}
                showSettings={showSettings}
                options={options}
            ></SettingsDialog>

            {/* <GuideDialog></GuideDialog> */}

            {/* LOADING DIALOG */}
            {!btnEnabled && <LoadingDialog id="loadingDialog"/>}


            {/* SCHERMATA 1 */}

            {step == 1 && <div className='home-step1'>
                <div style={{marginTop: 70 + "px", height: 245 + "px"}}>
                    <AppLogo id="logo"/>
                </div>
                
                <div className='step-body'>
                    {!isParamValid && <CommonButton   //PLAY BUTTON
                        id="play" 
                        color={btnColors["orange"]} 
                        txt="Gioca ora!" 
                        size={btnSizes["default"]}
                        click={() => fadeStep(null)}
                    />}
                    {isParamValid && <Loading id="paramLoading"/>}
                </div>
                
                <span className='credits'>Creato da <a href='https://tommasofaraci.it/' target='_blank'>Tommaso Faraci</a> - Prodotto non ufficiale</span>
                <div id="policies" className='check-container' style={{marginTop: "0px", marginBottom: "10px"}}>
                        <PrivacyPolicyLink></PrivacyPolicyLink>
                        <div style={{width: "20px"}}></div>
                        <CookiePolicyLink></CookiePolicyLink>
                </div>
            </div>}


            {/* SCHERMATA 2 */}

            {step == 2 && <div className='home-step2' style={{opacity: 0}}>
                <Title
                    txt="Come userai il dispositivo?"
                />

                <div className='step-body'>
                    <CommonButton   //DISPLAY SELECT
                        id="display" 
                        color={btnColors["orange"]} 
                        txt="DISPLAY" 
                        size={btnSizes["choice1"]}
                        click={selectDisplay}
                    />
                    <CommonButton   //BUTTON SELECT
                        id="button" 
                        color={btnColors["blue"]} 
                        txt="PULSANTE" 
                        size={btnSizes["choice2"]}
                        click={selectButton}
                    />
                </div>

            </div>}


            {/* STEP 3 DISPLAY */}

            {step == 3 && globalVars.type == "d" && <div className='home-step3 step3-d' style={{opacity: 0}}>
                <Loading id="loading" color={loadingColors["blue"]}/>
            </div>}
            {step == 3 && globalVars.type == "d" && <div className='home-step2'></div>}


            {/* STEP 3 BUTTON */}
            {step == 3 && globalVars.type == "b" && <div className='home-step3' style={{opacity: 0}}>
                <Title
                    txt="Inserisci il codice della partita"
                />

                <div className='step-body'>
                    <Input
                        id="code"
                        size={inputSizes["default"]}
                        type="text"
                        color={inputColors["default"]}
                        placeholder="CODICE"
                        onChange={onInputChange}
                        confirm={() => {
                            if(canConfirmRef.current) {
                                checkCode();
                            }
                        }}
                    />
                    <CommonButton   //DISPLAY SELECT
                        id="doneCode" 
                        color={canConfirm ? btnColors["green"] : btnColors["grey"]}
                        txt="Continua" 
                        size={btnSizes["default"]}
                        click={() => {
                            if(canConfirm) {
                                checkCode()
                            }
                        }}
                        enabled={btnEnabled}
                    />
                </div>
                
                

            </div>}
            

            {/* STEP 4 DISPLAY */}
            {step == 4 && globalVars.type == "d" && <div className='home-step4' style={{opacity: 0}}>
                <Title
                    txt="Partita creata!"
                />

                <div className='step-body'>
                    <div className='home-step4-d-1'>
                        <div className='home-step4-d-2'>
                            <QRGenerator data={"https://intesavincente.tommasofaraci.it/"+globalVars.code} className={"display-qr"} />
                            <Input
                                id="copyCode"
                                size={inputSizes["done"]}
                                color={inputColors["done"]}
                                value={globalVars.code}
                                readOnly={true}
                            />
                        </div>
                        <div className='home-step4-d-2'>
                            <span id="display-instructions">
                                Accedi alla partita dal tuo<br/>
                                smartphone e crea le squadre<br/>
                                per iniziare a giocare!
                            </span>
                            <div className='home-step4-d-3'>
                                <Loading id="waiting-loading" color={loadingColors["grey"]} speed={loadingSpeeds["slow"]}/>
                                <span id='display-waiting'>Attesa dispositivo...</span>
                            </div>
                        </div>
                        
                    </div>
                    
                    
                </div>
            </div>}



            {/* BOTTONI SOTTO */}
            <div className='bottom'>
                <CommonButton   //GUIDE BUTTON
                    id="guide"
                    color={btnColors["orange"]}
                    txt={settingsIcon}
                    size={btnSizes["mini"]}
                    click={() => {
                        showSettings(true);
                    }}
                />
                <CommonButton   //FULLSCREEN BUTTON
                    id="fullscreen"
                    color={btnColors["blue"]}
                    txt={globalVars.fullscreen}
                    size={btnSizes["mini"]}
                    click={fullScreen}
                />
            </div>
        </div>
    );
}



async function generateCode() {
    //GENERA STRINGA RANDOM
    let exists = true;
    let s="";
    while (exists) {
      for(let i=0;i<6;i++) {
        s += randomUppercaseLetter();
      }
      exists = await keyExists(s);
    }
    
    return s;
  
    function randomUppercaseLetter() {
      const asciiCodeOfA = 65; // Codice ASCII per 'A'
      const asciiCodeOfZ = 90; // Codice ASCII per 'Z'
      const randomAsciiCode = Math.floor(Math.random() * (asciiCodeOfZ - asciiCodeOfA + 1)) + asciiCodeOfA;
      return String.fromCharCode(randomAsciiCode);
    }
}

async function joinGame(code) {
    let exists = await keyExists(code);

    if(exists) {
        //console.log("esiste");

        let exists = await keyExists(code+"/button") || !await keyExists(code+"/display");
        if (exists) {
            //già in uso
            return Promise.reject("ERR_GAME_FULL");
        } else {
            //non in uso
            await Promise.all([
                setValue(code+"/button", "online"),
                setValue(code+"/music", localStorage.getItem("music")),
                setValue(code+"/effects", localStorage.getItem("effects")),
            ])
            return Promise.resolve("partita in attesa di bottone");
        }
    } else {
        //console.log("non esiste");
        return Promise.reject("ERR_GAME_NOTFOUND");
    }
}

export default Home;