import React, { useEffect } from 'react';

const CookiePolicyLink = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://cdn.iubenda.com/iubenda.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);

  return (
    <a
      href="https://www.iubenda.com/privacy-policy/15298448/cookie-policy"
      className="iubenda-black iubenda-noiframe iubenda-embed iubenda-noiframe"
      title="Cookie Policy"
    >
      Cookie Policy
    </a>
  );
};

export default CookiePolicyLink;
