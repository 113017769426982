import React from 'react';
import logo from './title logo.png';
import tag from './title logo tag.png'
import './appLogo.css';

const AppLogo = ({id}) => {
    return (
        <div className='app-logo' id={id}>
            <img src={logo} alt="App Logo" className="logo" />
            <img src={tag} alt="App Logo" className="logo-tag" />
            
        </div>
        
    );
};

export default AppLogo;
