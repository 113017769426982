import React from 'react';
import './Loading.css';

export const loadingColors = {
    "blue" : "circular-progress-blue",
    "white" : "circular-progress-white",
    "grey" : "circular-progress-grey"
}

export const loadingSpeeds = {
    "normal" : "circular-progress-speed-normal",
    "slow" : "circular-progress-speed-slow"
}

const Loading = ({id, color, speed=loadingSpeeds["normal"], style}) => {
    const cl = "circular-progress " + color + " " + speed;
    return (
        <div className={cl} id={id} style={style}>
        </div>
    );
}

export default Loading;