import React, { useEffect, useState } from 'react';
import './List.css'
import ListItem from './listItem';
import { globalVars } from '../../App';

export const listTypes = {
    normal: 1,
    ranking: 2,
    myTeams: 3,
    wordGroups: 4,
    customWords: 5,
}

let maxHeight = 0;
const List = ({id, listData, fixed, height=null, interactable, onItemClick, type=listTypes.normal, onItemDelete, placeHolder, onChecked }) => {
    //se fixed la lista non cambia nel tempo
    const elements = listData;
    const count = listData.length;
    const [lastCount, setlastCount] = useState(0);

    let newItem = false;
    let cl = "list";
    if(!fixed) {
        if(count > lastCount) {
            //console.log("new item");
        }
        if(count != lastCount) {
            newItem = true;
            setTimeout(() => {
                setlastCount(count);
            }, 300);
            //setlastCount(count);
        }
    }
    if(count == 0) {
        cl += " list-empty";
    }

    if(globalVars.type == "b") {
        cl += " hide-scrollbar";
    }

    //console.log("maxHeight: "+ maxHeight);
    /*if(!maxHeight) {
        //alla creazione della lista calcola l'altezza effettiva della lista
        setTimeout(() => {
            //attendi che venga effettivamente renderizzata la lista
            const list = document.getElementById(id);
            maxHeight = list.clientHeight - 40; //padding

            list.style.maxHeight = maxHeight + "px"; 
            //console.log("max: "+ maxHeight);
        }, 10);
    } else {
        setTimeout(() => {
            const list = document.getElementById(id);
            list.style.maxHeight = maxHeight + "px"; 
        }, 10);
    }*/

    let fsListen = document.addEventListener('fullscreenchange', function(event) {
        //se cambia lo stato dello schermo aggiorna il massimo della lista
        growList();
    });

    useEffect(() => {
        return () => {
            if(fsListen) {
                document.removeEventListener(fsListen);
            }
        }
    }, [])

    useEffect(() => {
        if(type == listTypes.normal) {
            growList();
            setTimeout(() => {
                try {
                    const list = document.getElementById(id).lastChild.scrollIntoView({ behavior: 'auto', block: 'end', inline: 'nearest' });
                } catch (e) {
                    
                }
            }, 10);
        }
    }, [listData])

    const growList = () => {
        if(count != 0) {
            //nascondi tutti gli elementi della lista
            //lasciala crescere
            //memorizza la massima altezza e impostala
            //riattiva gli elementi
            let es = document.getElementById(id);
            if(es) {
                es = es.querySelectorAll("div");
                //console.log(es);
                const list = document.getElementById(id);
                es.forEach((div) => {
                    div.style.display = "none";
                })
                
                list.style.maxHeight = "none";
                setTimeout(() => {
                    maxHeight = list.clientHeight - 40; //padding
                    list.style.maxHeight = maxHeight + "px"; 
                    //console.log("max: "+ maxHeight);

                    es.forEach((div) => {
                        div.style.display = "flex";
                    })
                }, 10);
            }
            
        }
        
    }

    /* rimuovi "count > lastCount" per attivare growList" */
    return (
        <div id={id} className={cl} style={height != null ? {height} : {}}>
            {count > 0 && elements.map((element, index) => (
                <ListItem
                    key={element.name}
                    index={index}
                    name={element.name}
                    newItem={index+1 == count && !fixed && count > lastCount ? true : false}
                    interactable={interactable}
                    onClick={onItemClick}
                    type={type}
                    score={element.score}
                    selected={element.selected}
                    enabled={element.enabled}
                    onDelete={onItemDelete}
                    onChecked={onChecked}
                    isDouble={element.isDouble}
                />
            ))}
            {count == 0 && <span id='empty-list-txt'>{placeHolder || "Nessuna squadra"}</span>}
        </div>
    );
}

//dovrebbe trasferire l'indice per poter capire se è l'ultimo elemento e animarlo
export default List;