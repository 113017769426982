import React, { useEffect, useRef, useState } from 'react';
import redBtn_idle from "../../../assets/images/redbutton_idle.png"
import redBtn_clicked from "../../../assets/images/redbutton_clicked.png"
import { firebaseCommands } from '../Game';
import { setValue } from '../../../firebase';
import { globalVars } from '../../../App';
import CommonButton, { btnColors, btnSizes } from '../../../Objects/CommonButton/CommonButton';

export const gameStatus_button = {
    idle: 1,
    playing: 2,
    guessing: 3,
    results: 4,
    showEnd: 5,
}

const titles = [
    "CLICCA PER\nGENERARE!",
    "CONCENTRATI...",
    "PARLA ORA!",
    "ATTESA RISULTATI..."
]

const Game_button = ({game, changeGame}) => {
    let redBtn = useRef(null);
    //probabilmente inutile, ma react non va bene per i giochi
    let clicked = false;

    const gameRef = useRef(game);
    const canClickRef = useRef(null);

    const [canClick, setCanClick] = useState(false);
    const [curScore, setCurScore] = useState(0);

    useEffect(() => {
        gameRef.current = game; // Aggiorna il riferimento allo stato più recente di game ogni volta che cambia

        if(gameRef.current.step != game.step) {
            //è atato aggiornato lo step
            setCanClick(false);
            setTimeout(() => {
                setCanClick(true);
            }, 1000);
        }
        
    }, [game]);

    useEffect(() => {
        //console.log("JUST LOADED ", game.loadCanClick,  game.loadCurScore)
        if("loadCanClick" in game && "loadCurScore" in game) {
            //ha appena caricato lo stato, aggiorna anche le altre variabili
            //console.log("JUST LOADED ", game.loadCanClick,  game.loadCurScore)
            setCurScore(game.loadCurScore);
            delete game.loadCurScore;
        } else {
            const states = {
                game,
                curScore
            }
            setValue(globalVars.code+"/btn_status", states);
        }
    }, [game, canClick, curScore])

    useEffect(() => {
        canClickRef.current = canClick; // Aggiorna il riferimento allo stato più recente di game ogni volta che cambia
    }, [canClick]);

    useEffect(() => {
        const redBtnElement = redBtn.current;
        if (redBtnElement) {
            // Aggiungi gli event listener
            redBtnElement.addEventListener('click', redBtnClick);
            redBtnElement.addEventListener('touchstart', redBtnClick);
            
            setTimeout(() => {
                setCanClick(true);
            }, 1000);
            // Rimuovi gli event listener quando il componente viene smontato o le dipendenze cambiano
            return () => {
                redBtnElement.removeEventListener('click', redBtnClick);
                redBtnElement.removeEventListener('touchstart', redBtnClick);
            };
        }
    }, [game, canClick]);


    const redBtnClick = (event) => {
        //console.log("btn event")
        // Verifica se l'evento è un tocco o un click del mouse
        if((event.type === 'click' || (event.touches && event.touches.length == 1)) && canClickRef.current && !clicked) {
            clicked = true;
            const currentGame = gameRef.current;
            setCanClick(false);
            // Controlla lo stato del gioco e invia il comando corrispondente
            // Lo stato da controllare deve essere lo stato locale del bottone
            // Invia il comando come funzione firebase al display
            
            //lo stato del bottone corrisponde allo stato del database
            //console.log("cur event: "+event.type);
            if(currentGame.step == gameStatus_button.idle) {
                // GENERA LA PAROLA
                if(currentGame.useDouble) {
                    firebaseCommands.doubleWord.call(firebaseCommands.creator);
                    setTimeout(() => {
                        changeGame(prevStatus => ({
                            ...prevStatus,
                            step: gameStatus_button.playing,
                            double: currentGame.double - 1,
                            useDouble: false
                        }));
                    }, 100);
                } else {
                    firebaseCommands.generateWord.call(firebaseCommands.creator);
                    setTimeout(() => {
                        changeGame(prevStatus => ({
                            ...prevStatus,
                            step: gameStatus_button.playing
                        }));
                    }, 100);
                }

                setValue(globalVars.code+"/status", gameStatus_button.playing);

                return;
            }

            if(currentGame.step == gameStatus_button.playing) {
                //STOP TIMER, INDOVINA LA PAROLA
                firebaseCommands.startGuessing.call(firebaseCommands.creator);
                setValue(globalVars.code+"/status", gameStatus_button.guessing);
                setTimeout(() => {
                    changeGame(prevStatus => ({
                        ...prevStatus,
                        step: gameStatus_button.guessing
                    }));
                }, 200);
                
                return;
            }
        }
    }
    
    const showResults = (wait=2000) => {
        changeGame(prevStatus => ({
            ...prevStatus,
            step: gameStatus_button.results
        }));
        //chiama subito la funzione per evitare problemi con le disconnessioni
        //passa l'attesa come parametro e metti un wait alla ricezione della chiamata
        //console.log(wait);
        firebaseCommands.showResults.call(firebaseCommands.creator, true, wait);
    }

    return (
        <div className='game_button'>

            {game.step != 1 && <span className='title-txt' id='redBtn_title'>{titles[game.step-1]}</span>}
            {game.step == 1 && <span className='title-txt' id='redBtn_title-1'>{titles[game.step-1]}</span>}

            {(game.step == gameStatus_button.idle || game.step == gameStatus_button.playing) && <div>
                <img src={game.step == gameStatus_button.idle ? redBtn_idle : redBtn_clicked} alt="Pulsante rosso" ref={redBtn} className='redBtn'></img>
            </div>}
            
            {game.step == gameStatus_button.idle && <CommonButton
                id={"doubleBtn"} txt={"RADDOPPIO"}
                color={
                    game.useDouble ? btnColors.green :
                    game.double > 0 && curScore >= 2 ? btnColors.orange :
                    btnColors.grey
                }
                size={btnSizes.default}
                click={() => {
                    //ATTIVA O DISATTIVA RADDOPPIO
                    if(game.double > 0 && curScore >= 2 && canClick) {
                        changeGame(prevStatus => ({
                            ...prevStatus,
                            useDouble: !game.useDouble
                        }));
                    }
                    
                }}>
            </CommonButton>}
            
            {game.step == gameStatus_button.guessing && <div style={{display:'flex', alignItems: 'center', justifyContent: 'center', flexFlow: 'column'}}>
                <CommonButton id={"correct_ans"} txt={"CORRETTA"} color={btnColors.green} size={btnSizes.choice1} click={() => {
                    //RISPOSTA CORRETTA
                    //console.log("CORR: ", canClick);
                    if (canClick) {
                        setCanClick(false);
                        
                        setCurScore(curScore+1);
                        firebaseCommands.correct.call(firebaseCommands.creator);
                        setValue(globalVars.code+"/status", gameStatus_button.idle);
                        
                        if(game.last) {
                            showResults();
                        } else {
                            changeGame(prevStatus => ({
                                ...prevStatus,
                                step: gameStatus_button.idle
                            }));
                        }

                        setTimeout(() => {
                            setCanClick(true);
                        }, 1000);
                    }
                    
                }}></CommonButton>
                <div style={{height: "20px"}}></div>
                <CommonButton id={"wrong_ans"} txt={"ERRATA"} color={btnColors.red} size={btnSizes.choice2} click={() => {
                    //RISPOSTA ERRATA
                    if (canClick) {
                        setCanClick(false);

                        if(curScore > 0) {
                            setCurScore(curScore-1);
                        }
                        firebaseCommands.wrong.call(firebaseCommands.creator);
                        setValue(globalVars.code+"/status", gameStatus_button.idle);
                        if(game.last) {
                            showResults();
                        } else {
                            changeGame(prevStatus => ({
                                ...prevStatus,
                                step: gameStatus_button.idle,
                            }));
                        }

                        setTimeout(() => {
                            setCanClick(true);
                        }, 1000);
                    }
                    
                }}></CommonButton>
                <div style={{height: "30px"}}></div>
                <CommonButton id={"skip"} txt={game.last ? "SALTA" : "PASSO"} color={btnColors.orange} size={btnSizes.default} click={() => {
                    if((game.skips > 0 || game.last) && canClick) {
                        //PASSO
                        setCanClick(false);

                        firebaseCommands.skip.call(firebaseCommands.creator);
                        setValue(globalVars.code+"/status", gameStatus_button.results);
                        if(game.last) {
                            showResults(0);
                        } else {
                            changeGame(prevStatus => ({
                                ...prevStatus,
                                skips: game.skips -1,
                                step: gameStatus_button.idle,
                            }));
                        }

                        setTimeout(() => {
                            setCanClick(true);
                        }, 1000);
                    }

                }} enabled={game.skips > 0 || game.last}></CommonButton>
            </div>}
            
            {game.step == gameStatus_button.showEnd && <div style={{display:'flex', alignItems: 'center', justifyContent: 'center', flexFlow: 'column'}}>
                <CommonButton
                    id="replay"
                    txt="Gioca ancora!"
                    color={btnColors["orange"]}
                    size={btnSizes["default-wide"]}
                    click={() => {
                        firebaseCommands.replay.call(firebaseCommands.creator);
                    }}
                />
                <div style={{height: "15px"}}></div>
                <CommonButton
                    id="goHome"
                    txt="Torna alla home"
                    color={btnColors["blue"]}
                    size={btnSizes["wide"]}
                    click={() => {
                        firebaseCommands.endGame.call(firebaseCommands.creator);
                    }}
                />
            </div>}
        </div>
    );
}

export default Game_button;