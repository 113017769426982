import React, { useEffect } from 'react';
import { useState, useRef } from 'react';
import logoImg from "../../../assets/images/logo.png"
import "../../../SplashScreen/SplashScreen.css"
import videoSource from "../../../assets/videos/intro.mp4"
import AudioComponent from '../../../AudioComponent';
import introBg from "../../../assets/musics/Intro.mp3"
import inBetween from "../../../assets/musics/inBetween.mp3"
import { globalVars } from '../../../App';
import CommonButton, { btnColors, btnSizes } from '../../../Objects/CommonButton/CommonButton';
import Game, { firebaseCommands } from '../Game';


const CutScene = ({ intro, onEnd, destroy, last, musicVolume, canSkip }) => {
    const videoRef = useRef(null);
    const logo = useRef(null);
    const splash = useRef(null);
    const audioRef = useRef(null);
    //const softInterval = useRef(null);

    //console.log("cutscene "+ onEnd);

    const [hideVideo, setHide] = useState(false);
    const [showSkip, setSkipable] = useState(false);

    useEffect(() => {
        let videoElement;
        if(globalVars.type == "b") {
            setTimeout(() => {
                let skip = document.getElementById("skip");
                skip.style.opacity = 0;
            }, 10);
        }
        
        
        //se deve mostrare lo splash, attendi il fade in prima di caricare il video
        if(intro) {
            splash.current.classList.add("fadein");
            setTimeout(() => {
                logo.current.classList.add("fadein");
                //softInterval.current = setInterval(softFade, 1700);
                setTimeout(() => {
                    //softFade();
                    if(globalVars.type == "d") {
                        videoElement.style.display = "block";
                    }
                }, 300);
            }, 300);
            
        } else {
            if(globalVars.type == "d") {
                document.querySelector(".cutScene").classList.add("fadein");
                videoRef.current.style.display = "block";
            }
        }

        if(globalVars.type == "d") {
            // Aggiungi un event listener per 'loadeddata' che ferma il video
            videoElement = videoRef.current;
            videoElement.addEventListener('loadeddata', () => {

                //fade out splash e avvio manuale del video
                if(intro) {
                    videoElement.pause(); // Ferma il video non appena viene caricato

                    setTimeout(() => {
                        //clearInterval(softInterval.current);
                        splash.current.classList.remove("fadein");
                        logo.current.classList.remove("fadein");
                        logo.current.classList.add("fadeout");
                        setTimeout(() => {
                            splash.current.classList.add("fadeout");
                            videoElement.play();
                            
                            firebaseCommands.startVideo.call(firebaseCommands.creator, last);
                        }, 300);
                        
                        
                        audioRef.current.play();

                    }, 2000);
                } else {
                    videoElement.play();
                    firebaseCommands.startVideo.call(firebaseCommands.creator, last);
                    if(!last) {
                        audioRef.current.play();
                    }
                }
            });


            videoElement.addEventListener("ended", () => {
                onEnd();
                setHide(true);
                document.querySelector(".cutScene").style.display = "none";
            });

            audioRef.current.addEventListener("ended", () => {
                destroy(false);   //viene passata la funzione useState, imposta il valore di cutScene a false
            });
        
        }

        const softFade = () => {
            logo.current.classList.remove("soft-fadein");

            logo.current.classList.add("soft-fadeout");
            setTimeout(() => {
                logo.current.classList.remove("soft-fadeout");
                logo.current.classList.add("soft-fadein");
            }, 700);
        }

        return () => {
            //clearInterval(softInterval.current)
            //softInterval.current = null;
            if(globalVars.type == "d") {
                // Rimuovi l'event listener quando il componente viene smontato
                videoElement.removeEventListener('loadeddata', () => {
                    videoElement.pause();
                });
            }
        };
    }, []);

    useEffect(() => {
        if(globalVars.type == "b" && typeof onEnd != "function" && onEnd == true) {
            //console.log("onEnd TRUE");
            //clearInterval(softInterval.current);
            //il pulsante ha ricevuto l'ordine di terminare l'animazione
            splash.current.classList.remove("fadein");
            logo.current.classList.remove("fadein");
            logo.current.classList.add("fadeout");
            document.getElementById("skip").classList.remove("fadein");
            document.getElementById("skip").classList.add("fadeout");
            setTimeout(() => {
                splash.current.classList.add("fadeout");
                setTimeout(() => {
                    destroy(false);   //viene passata la funzione useState, imposta il valore di cutScene a false
                }, 300);
            }, 300);
        }
    }, [onEnd])

    useEffect(() => {
        if(canSkip) {
            if(globalVars.type == "b") {
                //l'utente può skippare l'intro
                setTimeout(() => {
                    let skip = document.getElementById("skip");
                    skip.style.opacity = null;
                    skip.classList.add("fade-in");
                    setSkipable(true);
                }, 1200);
            } else {
                //l'intro è stata skippata
                videoRef.current.currentTime = videoRef.current.duration; // Porta il video alla fine
                videoRef.current.dispatchEvent(new Event('ended'));
                if(!last) {
                    audioRef.current.currentTime = 6.85;
                }
            }
            
        }
    }, [canSkip]);
  
  return (
      <div className='cutScene'>
          {intro && !hideVideo && <div className='splash-screen' ref={splash}>
            <img src={logoImg} alt="Logo" className="_logo" ref={logo}/>
            {globalVars.type == "b" && <div style={{position: 'absolute', bottom: '50px'}}>
                <CommonButton
                    id="skip"
                    txt="Salta"
                    color={btnColors.orange}
                    size={btnSizes.default}
                    enabled={showSkip}
                    click={() => {
                        if(showSkip) {
                            setSkipable(false);
                            firebaseCommands.skipVideo.call(firebaseCommands.creator);
                        }
                    }}
                ></CommonButton>
            </div>}
          </div>}

          {globalVars.type == "d" && !hideVideo && <video
              style={{display: "none"}}
              ref={videoRef}
              width="100%"
              height="100%"
          >
              <source src={videoSource} type="video/mp4" />
              Il tuo browser non supporta i video.
          </video>}

          {globalVars.type == "d" && <AudioComponent ref={audioRef} path={intro ? introBg : inBetween} volume={musicVolume}></AudioComponent>}
      </div>
  );
}

export default CutScene;