import React from 'react';
import './Dialog.css'
import { useState } from 'react';
import CommonButton, {btnColors, btnSizes} from '../CommonButton/CommonButton';

export const dialogColors = {
    default: "dialog-bg-color-def",
    color1: "dialog-bg-color-1"
}

const Dialog = ({id, title, txt, color, width="500px", height="350px", common, confirm, cancel, canConfirm, confirmDestructive}) => {
    const screenWidth = window.innerWidth;
    const scale = screenWidth <= 600 ? screenWidth / 600 : 1;
    const multi = (common && cancel) || (common && confirm) || (cancel && confirm);

    width = screenWidth <= 600 ? "80%" : width;
    
    return (
        <div className='dialog' id={id} style={{opacity: 0}}>
            <div className={"dialog-box "+color} style={{width, minHeight: height}}>
                <div>
                    <span className={"dialog-title "+color}>{title}</span>
                    <div className='dialog-div'></div>
                </div>
               <span className='dialog-txt'>{txt}</span>
               <div className={multi ? "dialog-btns-multi" : "dialog-btns"}>
                    {common!=null && <CommonButton
                        id="close" 
                        color={btnColors["blue"]}
                        txt="Chiudi" 
                        size={multi ? btnSizes["button-multi"] : btnSizes["button"]}
                        click={common}
                    />}
                    {cancel!=null && <CommonButton
                        id="cancel" 
                        color={btnColors["blue"]} 
                        txt="Indietro" 
                        size={multi ? btnSizes["button-multi"] : btnSizes["button"]}
                        click={cancel}
                    />}
                    {confirm!=null && <CommonButton
                        id="confirm" 
                        color={canConfirm ? confirmDestructive ? btnColors["red"] : btnColors["green"] : btnColors["grey"]} 
                        txt="Conferma" 
                        size={multi ? btnSizes["button-multi"] : btnSizes["button"]}
                        click={() => {
                            if(canConfirm) {
                                confirm();
                            }
                        }}
                    />}
               </div>
            </div>
        </div>
    )
}

export default Dialog;