import React from 'react';
import QRCode from 'react-qr-code';

function QRGenerator({ data, className }) {
  return (
    <div className={className}>
      <QRCode value={data} size={200}/>
    </div>
  );
}

export default QRGenerator;
