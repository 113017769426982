import React, { useEffect, useRef } from 'react';
import '../Animations.css'
import { useState } from 'react';
import Input, { inputColors, inputSizes } from '../../../Objects/Input/Input';
import { btnColors, btnSizes } from '../../../Objects/CommonButton/CommonButton';
import AudioComponent from '../../../AudioComponent';
import TimerComponent from "../../../Objects/TimerComponent"
import { firebaseCommands } from '../Game';

//MUSIC
import bgm_audio from "../../../assets/musics/bgm.mp3";
import showScore_audio_1 from "../../../assets/musics/showScore_1.mp3";

//EFFECTS
import double_up_audio from "../../../assets/sounds/double_up.mp3"
import correct_audio from "../../../assets/sounds/correct.mp3"
import new_word_audio from "../../../assets/sounds/new_word.mp3"
import ready_audio from "../../../assets/sounds/ready.mp3"
import time_out_audio from "../../../assets/sounds/time_out.mp3"
import wrong_audio from "../../../assets/sounds/wrong.mp3"


export const gameStatus_display = {
    showTeam: 1,
    showBox: 2,
    newWord: 3,
    guessWord: 4,
    wrong: 5,
    correct: 6,
    skip: 7,
    double: 8,
    showResults: 9,
    lastGuess: 10,
    guessing: 11,
    pause: 12,
    resume: 13,
    lastResults: 14,
    endGame: 15,
}

const Game_display = ({ game, teams, changeGame, changeTeams, words, doubleWords,
    customWords, customDoubleWords, readKeys, setReadKeys, readDoubleKeys, setReadDoubleKeys,
    readCustomKeys, setCustomReadKeys, readCustomDoubleKeys, setCustomReadDoubleKeys,
    forceStandards, forceDoubleStandards, musicVolume, effectsVolume, percent}) => {
    
    const [curTeam, setCurTeam] = useState("");
    let team = useRef(null);
    let word = useRef(null);
    let score = useRef(null);
    let time = useRef(null);

    const [timer, setTimer] = useState({
        active: false,
        timeLeft: 60
    });

    const [turnTimer, setTurnTimer] = useState({
        active: false,
        timeLeft: 6
    });

    //MUSIC
    let bgm = useRef(null);
    let bgm_2 = useRef(null);
    let showScore = useRef(null);

    //EFFECTS
    let double_up = useRef(null);
    let correct = useRef(null);
    let new_word = useRef(null);
    let ready = useRef(null);
    let time_out = useRef(null);
    let wrong = useRef(null);

    let wordClass = "teamNameContainer " + inputColors.done;
    let mini_cl1 = "mini-side";
    let mini_cl2 = "mini-side";
    //console.log("CUR 1: ",game.cur);
    if(game.cur%2 == 0) {
        //SQAUDRE DISPARI (PRIMA, TERZA...) condizione invertita perché l'indice è 0-based
        mini_cl1 += " mini-trans-right mini-side-color-3";
        mini_cl2 += " mini-trans-left mini-side-color-2";
    } else {
        //SQAUDRE PARI (SECONDA, QUARTA...)
        mini_cl1 += " mini-trans-left mini-side-color-1";
        mini_cl2 += " mini-trans-right mini-side-color-2";
    }

    useEffect(() => {
        /* MOSTRA IL NOME DELLA SQUADRA IN GIOCO */
        if(game.step == gameStatus_display.showTeam) {
            //console.log(teams, game.cur);
            setCurTeam(teams[game.cur]);

            setTimeout(() => {
                try {
                    team.current.classList.add("fadein");
                    setTimeout(() => {
                        try {
                            team.current.classList.remove("fadein");
                            team.current.classList.add("fadeout");
                            setTimeout(() => {
                                changeGame((prevState) => ({
                                    ...prevState,
                                    step: 2,
                                }));
                                bgm.current.play();
                                //bgm.current.currentTime = 4 * 60 + 35.0478
                                //bgm_2.current.currentTime = 4 * 60 + 35.0478
                            }, 300);
                        } catch (e) {
                            return;
                        }
                    }, 1500);
                } catch (e) {
                    return;
                }
                
            }, 200);
        }

        /* ENTRA IN SCENA IL BOX DELLA PAROLA, IL TEMPO E IL PUNTEGGIO */
        if(game.step == gameStatus_display.showBox) {
            //animazione e segnale al bottone con firebase
            firebaseCommands.showButton.call(firebaseCommands.creator);

            double_up.current.play();
            setTimeout(() => {
                //console.log("CUR 2: ",game.cur);
                if(game.cur%2 == 0) {
                    score.current.classList.add("enterRight");
                    time.current.classList.add("enterLeft");
                } else {
                    score.current.classList.add("enterLeft");
                    time.current.classList.add("enterRight");
                }
                
                setTimeout(() => {
                    score.current.classList.remove("enterLeft");
                    score.current.classList.remove("enterRight");
                    
                    time.current.classList.remove("enterLeft");
                    time.current.classList.remove("enterRight");

                    score.current.style.setProperty("translate", "0px");
                    time.current.style.setProperty("translate", "0px");
                }, 200);
            }, 300);
        }

        /* GENERA UNA NUOVA PAROLA */
        if(game.step == gameStatus_display.newWord) {
            //non puoi usare isDouble per un problema di ripetizione a causa di useEffect
            if(word.current.classList.contains("pop_in")) {
                word.current.classList.remove(inputColors.blue);
                double_up.current.play();

                word.current.classList.remove("pop_in");
                word.current.classList.add("pop_out");
                
                score.current.classList.remove("shiftLeft_in");
                score.current.classList.remove("shiftRight_in");
                time.current.classList.remove("shiftLeft_in");
                time.current.classList.remove("shiftRight_in");

                if(game.cur%2 == 0) {
                    score.current.classList.add("shiftRight_out");
                    time.current.classList.add("shiftLeft_out");
                } else {
                    score.current.classList.add("shiftLeft_out");
                    time.current.classList.add("shiftRight_out");
                }                

                changeGame(prevState => ({
                    ...prevState,
                    step: gameStatus_display.guessing
                }));

                setTimeout(() => {
                    //mostra una parola a caso
                    word.current.querySelector("span").innerHTML = getRandomWord();

                    setTimeout(() => {
                        score.current.classList.remove("shiftLeft_out");
                        score.current.classList.remove("shiftRight_out");
                        time.current.classList.remove("shiftLeft_out");
                        time.current.classList.remove("shiftRight_out");
                        word.current.classList.remove("pop_out");
                        
                        setTimer((prevState) => ({
                            ...prevState,
                            active: true,
                        }));
                    }, 250);
                }, 250);

            } else {
                wordClass = "teamNameContainer "+ inputColors.done;
                word.current.className = wordClass;

                new_word.current.play();
                randomWords(word.current).then(() => {
                    //mostra una parola a caso
                    word.current.querySelector("span").innerHTML = getRandomWord();

                    setTimer((prevState) => ({
                        ...prevState,
                        active: true,
                    }));
                });
            }
            
        }

        /* FERMA IL TIMER, È IL MOMENTO DI INDOVINARE */
        if(game.step == gameStatus_display.guessWord) {
            ready.current.play();

            //inserisci un timer di 6 secondi
            //dopodiché la risposta è sbagliata
            setTimer((prevState) => ({
                ...prevState,
                active: false,
            }));

            /* setTurnTimer((prevState) => ({  //timer turno
                ...prevState,
                active: true,
                timeLeft: 6
            })); */
        }

        /* PAROLA SBAGLIATA */
        if(game.step == gameStatus_display.wrong) {
            wrong.current.play();
            /* setTurnTimer((prevState) => ({  //timer turno
                ...prevState,
                active: false,
                timeLeft: 6
            })); */

            let wt = 80;

            setTimeout(() => {
                word.current.classList.add(inputColors.error);

                setTimeout(() => {
                    word.current.classList.remove(inputColors.error);

                    setTimeout(() => {
                        word.current.classList.add(inputColors.error);

                        setTimeout(() => {
                            word.current.classList.remove(inputColors.error);

                        }, wt);
                    }, wt);
                }, wt);
            }, wt);

            if(curTeam.score > 0) {
                let newList = [...teams];
                newList[game.cur] = {
                    name: newList[game.cur].name,
                    score: newList[game.cur].score - (word.current.classList.contains("pop_in") ? 2 : 1)
                }
                changeTeams(newList);
            }
        }

        /* PAROLA CORRETTA */
        if(game.step == gameStatus_display.correct) {
            correct.current.play();
            /* setTurnTimer((prevState) => ({  //timer turno
                ...prevState,
                active: false,
                timeLeft: 6
            })); */

            let wt = 80;

            setTimeout(() => {
                word.current.classList.add(inputColors.blue);

                setTimeout(() => {
                    word.current.classList.remove(inputColors.blue);

                    setTimeout(() => {
                        word.current.classList.add(inputColors.blue);

                        setTimeout(() => {
                            word.current.classList.remove(inputColors.blue);

                            setTimeout(() => {
                                word.current.classList.add(inputColors.blue);
                                
                            }, wt);
                        }, wt);
                    }, wt);
                }, wt);
            }, wt);
            

            let newList = [...teams];
                newList[game.cur] = {
                    name: newList[game.cur].name,
                    score: newList[game.cur].score + (word.current.classList.contains("pop_in") ? 2 : 1)
                }
            changeTeams(newList);
        }

        /* HA USATO UN PASSO */
        if(game.step == gameStatus_display.skip) {
            /* setTurnTimer((prevState) => ({  //timer turno
                ...prevState,
                active: false,
                timeLeft: 6
            })); */
        }

        /* HA USATO UN RADDOPPIO */
        if(game.step == gameStatus_display.double) {
            double_up.current.play();

            wordClass = "teamNameContainer "+ inputColors.done;
            word.current.className = wordClass;
            word.current.classList.add("pop_in");

            if(game.cur%2 == 0) {
                score.current.classList.add("shiftRight_in");
                time.current.classList.add("shiftLeft_in");
            } else {
                score.current.classList.add("shiftLeft_in");
                time.current.classList.add("shiftRight_in");
            }
            

            setTimeout(() => {
                //mostra una parola a caso
                word.current.querySelector("span").innerHTML = getRandomWord(true);

                setTimeout(() => {

                    setTimer((prevState) => ({
                        ...prevState,
                        active: true,
                    }));
                }, 250);
            }, 250);
        }

        /* DEVE VISUALIZZARE I RISULTATI */
        if(game.step == gameStatus_display.showResults) {
            bgm.current.pause();
            bgm_2.current.pause();
            bgm.current.currentTime = 0;
            bgm_2.current.currentTime = 0;
            if(!game.isLast) {
                showScore.current.currentTime = 0;
                showScore.current.play();
            }

            const box = document.querySelector(".bottomBox");
            box.classList.remove("showUp");
            box.classList.add("hideDown");

            score.current.classList.add("hideDown");
            time.current.classList.add("hideDown");
        }

        /* IL PULSANTE SI È DISCONNESSO */
        if(game.step == gameStatus_display.pause) {
            if(timer.active) {
                setTimer(prevState => ({
                    ...prevState,
                    toResume: timer.active,
                    active: false
                }));
            }
            
            if(turnTimer.active) {
                /* setTurnTimer(prevState => ({
                    ...prevState,
                    toResume: turnTimer.active,
                    active: false
                })); */
            }
            
        }

        /* IL PULSANTE SI È CONNESSO NUOVAMENTE */
        if(game.step == gameStatus_display.resume) {
            setTimer(prevState => ({
                ...prevState,
                active: timer.toResume,
                toResume: false,
            }));

            if(game.prevStep == gameStatus_display.guessWord ||
                game.prevStep == gameStatus_display.lastGuess ||
                game.prevStep == gameStatus_display.showTeam) {
                changeGame(prevState => ({
                    ...prevState,
                    step: game.prevStep
                }));
            }
            
            
            /* setTurnTimer(prevState => ({
                ...prevState,
                active: turnTimer.toResume,
                toResume: false,
            })); */
            
        }
    }, [game]);

    useEffect(() => {
        if(game.cur != -1) {
            setCurTeam(teams[game.cur]);
        }
    }, [teams]);

    const getRandomWord = (double=false) => {
        const wordList = double ? doubleWords : words;
        const readKeysList = double ? readDoubleKeys : readKeys;
        const setReadKeysList = double ? setReadDoubleKeys : setReadKeys;
        const customWordList = double ? customDoubleWords : customWords;
        const customReadKeysList = double ? readCustomDoubleKeys : readCustomKeys;
        const setCustomReadKeysList = double ? setCustomReadDoubleKeys : setCustomReadKeys;
        const forceStds = double ? forceDoubleStandards : forceStandards

        //possibile parola vuota o ciclo infinito
        //console.log(words, customWords, doubleWords, customDoubleWords);
    
        //console.log(double ? "double words picked" : "single words picked");
    
        // Determina se pescare dalle liste custom o dalle liste normali
        let p = (1 - percent).toFixed(1);
        let useCustom = Math.random() < (forceStds ? 0.3 : p);

        if((useCustom && customWordList.length == 0) || (!useCustom && wordList.length == 0)) {
            useCustom = !useCustom;
        }

        //console.log("using custom: ", useCustom);

        let selectedWordList, selectedReadKeysList, selectedSetReadKeysList;

        if (useCustom) {
            selectedWordList = customWordList;
            selectedReadKeysList = customReadKeysList;
            selectedSetReadKeysList = setCustomReadKeysList;
        } else {
            selectedWordList = wordList;
            selectedReadKeysList = readKeysList;
            selectedSetReadKeysList = setReadKeysList;
        }

        // Se tutte le parole sono state lette, resetta l'array selectedReadKeysList
        //console.log(selectedReadKeysList.length, selectedWordList.length);
        if (selectedReadKeysList.length === selectedWordList.length) {
            selectedReadKeysList = [];
            selectedSetReadKeysList([]);
        }
        
        let randomWord;
        //console.log(selectedReadKeysList.length, selectedWordList.length);
        do {
            randomWord = selectedWordList[Math.floor(Math.random() * selectedWordList.length)];
        } while (selectedReadKeysList.includes(randomWord));

        // Aggiungi la parola selezionata all'array selectedReadKeysList
        selectedSetReadKeysList(prevReadKeys => [...prevReadKeys, randomWord]);

        return randomWord;
    }

    const formatTime = (time) => {
        return time < 10 ? `:0${time}` : ":"+time.toString();
    };

    const turnEnded = () => {
        time_out.current.play();
        //invia comando al bottone per rispondere
        //deve dare la possibilità di non rispondere
        firebaseCommands.turnEnded.call(firebaseCommands.creator);
        setTimer((prevState) => ({
            ...prevState,
            active: false,
        }));

        /* setTurnTimer((prevState) => ({  //timer turno
            ...prevState,
            active: true,
            timeLeft: 6
        })); */

        changeGame((prevState) => ({
            ...prevState,
            step: gameStatus_display.lastGuess,
        }));
        
    }

    return (
        <div className='game_display'>
            {(game.step == gameStatus_display.showTeam) && <div className='curTeam' ref={team}>
                <span className='title-txt'>GIOCANO:</span>
                
                <div className='teamNameContainer common-btn-orange'>
                    <span className='common-btn-txt'>{curTeam.name}</span>
                </div>
            </div>}

            {game.step >= 2 && <div className='gameView'>
                <div className='bottomBox showUp'>
                    {/* SQAUDRE PARI (SECONDA, QUARTA...) */}
                    {game.cur%2 != 0 && <div className='gameUI'>
                        <div className={mini_cl1} id='score' ref={score}>
                            <span className='score-txt'>{curTeam.score}</span>
                        </div>

                        <div className={wordClass} id='word' ref={word}>
                            <span className='game-word'>PREMI IL PULSANTE</span>
                        </div>

                        <div className={mini_cl2} id='time' ref={time}>
                            <span className='timer-txt'>{formatTime(timer.timeLeft)}</span>
                        </div>
                    </div>}
                    
                    {/* SQAUDRE DISPARI (PRIMA, TERZA...) condizione invertita perché l'indice è 0-based */}
                    {game.cur%2 == 0 && <div className='gameUI'>
                        <div className={mini_cl2} id='time' ref={time}>
                            <span className='timer-txt'>{formatTime(timer.timeLeft)}</span>
                        </div>

                        <div className={wordClass} id='word' ref={word}>
                            <span className='game-word'>PREMI IL PULSANTE</span>
                        </div>

                        <div className={mini_cl1} id='score' ref={score}>
                            <span className='score-txt'>{curTeam.score}</span>
                        </div>
                    </div>}
                </div>
                
                {/* TIMER PARTITA */}
                {timer.active && <TimerComponent
                    onTimerEnd = {turnEnded}
                    onTimeUpdate = {(time) => {
                        setTimer((prevState) => ({
                            ...prevState,
                            timeLeft: time
                        }));
                    }}
                    initialTime={timer.timeLeft}
                ></TimerComponent>}

                {/* TIMER TURNO */}
                {turnTimer.active && <TimerComponent
                    onTimerEnd = {() => {
                        // setTurnTimer({active: false});
                        if(game.step == gameStatus_display.guessWord) {
                            firebaseCommands.wrong.call(firebaseCommands.reciver);
                            firebaseCommands.cancelAnswer.call(firebaseCommands.creator);
                        } else {
                            firebaseCommands.showResults.call(firebaseCommands.creator);
                        }

                    }}
                    onTimeUpdate = {(time) => {
                        /* setTurnTimer((prevState) => ({
                            ...prevState,
                            timeLeft: time,
                        })); */
                    }}
                    initialTime={turnTimer.timeLeft}
                ></TimerComponent>}
                

                {turnTimer.active && turnTimer.timeLeft < 4 && <div className="turnTimer-bg">
                    <div className={"turnTimer "+btnColors.orange}>
                        <span className='turnTimer-text'>{turnTimer.timeLeft}</span>
                    </div>
                </div>}
            </div>}



            {/* AUDIO SOUNDS */}
            <AudioComponent ref={bgm} path={bgm_audio} volume={musicVolume} onTimeChange={
                (time) => {
                    if(time >= 4 * 60 + 44.027) {
                        bgm_2.current.play();
                        setTimeout(() => {
                            //la prima volta potrebbe non essere preciso a causa del caricamento del file
                            bgm.current.pause();
                            bgm.current.currentTime = 0;
                        }, 2000);
                    }
                }
            }></AudioComponent>
            <AudioComponent ref={bgm_2} path={bgm_audio} volume={musicVolume} onTimeChange={
                (time) => {
                    if(time >= 4 * 60 + 44.0478) {
                        bgm.current.play();
                        setTimeout(() => {
                            bgm_2.current.pause();
                            bgm_2.current.currentTime = 0;
                        }, 2000);
                    }
                }
            }></AudioComponent>

            <AudioComponent ref={showScore} path={showScore_audio_1} volume={musicVolume}></AudioComponent>

            <AudioComponent ref={double_up} path={double_up_audio} volume={effectsVolume}></AudioComponent>
            <AudioComponent ref={correct} path={correct_audio} volume={effectsVolume}></AudioComponent>
            <AudioComponent ref={new_word} path={new_word_audio} volume={effectsVolume}></AudioComponent>
            <AudioComponent ref={ready} path={ready_audio} volume={effectsVolume}></AudioComponent>
            <AudioComponent ref={time_out} path={time_out_audio} volume={effectsVolume}></AudioComponent>
            <AudioComponent ref={wrong} path={wrong_audio} volume={effectsVolume}></AudioComponent>
        </div>
    );
}

function randomLetter() {
    const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    return alphabet[Math.floor(Math.random() * alphabet.length)];
}

function wait(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

async function randomWords(word) {
    for (let i = 0; i < 10; i++) {
        let s = "";
        let n = Math.floor(Math.random() * 3) + 5
        for(let j = 0; j<n; j++) {
            s += randomLetter();
        }
        word.querySelector("span").innerHTML = s;
        await wait(50);
    }
}

export default Game_display;