import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Dialog, { dialogColors } from './Dialog/Dialog';
import CommonButton, { btnColors, btnSizes } from './CommonButton/CommonButton';
import guideIcon from "../assets/images/guide.png"
import { globalVars } from '../App';
import { setValue } from '../firebase';


const SettingsDialog = ({ settings, showSettings, options }) => {
    const [clearData, setClearData] = useState(false);
    const remoteBtn = globalVars.type == "b" && globalVars.code;
    const remoteDisp = globalVars.type == "d" && globalVars.code;

    useEffect(() => {
        if(settings) {
            setTimeout(async () => {
                let d = document.querySelector("#settings-dialog");
                d.style.opacity = null;
                d.classList.add("fade-in");

                if(!remoteDisp) {
                    if(localStorage.getItem("music")) {
                        options.setMusicVolume(localStorage.getItem("music"));
                    }
                    if(localStorage.getItem("effects")) {
                        options.setEffectsVolume(localStorage.getItem("effects"));
                    }
                    
                }
                setTimeout(() => {
                    adjustSlider("music-slider");
                    adjustSlider("effects-slider");
                    updateSliderVal("music-slider", options.setMusicVolume);
                    updateSliderVal("effects-slider", options.setEffectsVolume);
                }, 0);
                
            }, 10);
        }
    }, [settings]);

    useEffect(() => {
        if(clearData) {
            setTimeout(() => {
                let d = document.querySelector("#clear-dialog");
                d.style.opacity = null;
                d.classList.add("fade-in");
            }, 10);
        }
    }, [clearData]);
    
    const closePopup = (id, hide) => {
        let d = document.getElementById(id);
        d.classList.remove("fade-in")
        d.style.opacity = null;
        d.classList.add("fade-out");

        setTimeout(() => {
            hide(false);
        }, 100);
    }

    useEffect(() => {
        adjustSlider("music-slider");
    }, [options.musicVolume]);

    useEffect(() => {
        adjustSlider("effects-slider");
    }, [options.effectsVolume]);
    
    const adjustSlider = (id) => {
        const slider =document.getElementById(id);
        if(slider) {
            const percentage = slider.value * 100;
            slider.style.background = `linear-gradient(to right, rgb(241,222,4) ${percentage}%, rgb(253, 242, 114) ${percentage}%)`;
        }
    }

    const updateSliderVal = (id, _setValue) => {
        const slider =document.getElementById(id);
        _setValue(slider.value);
        localStorage.setItem(id.split("-")[0], slider.value);

        if(remoteBtn) {
            setValue(globalVars.code+"/"+id.split("-")[0], slider.value);
        }
    }

    return (
        settings ? (
            <>
            <Dialog
                id="settings-dialog"
                title="Opzioni"
                txt={<div id='groups-dialog' style={{justifyContent: "start"}}>
                    {remoteBtn && <span id='customWords-dialog-txt' style={{ opacity: "0.8"}}>Regola i volumi del display</span>}
                    {remoteBtn && <div style={{height: "20px"}}></div>}
                    <div className='slider-container' style={{marginTop: "0px"}}>
                        <span className='dialog-txt' style={{lineHeight: "30px", marginRight: "50px"}}>Musica</span>
                        <input
                            id='music-slider'
                            type="range"
                            min="0"
                            max="1"
                            step="0.01"
                            value={options.musicVolume}
                            onChange={() => {
                                updateSliderVal("music-slider", options.setMusicVolume);
                            }}
                            className="custom-slider"
                        />
                    </div>

                    <div className='slider-container'>
                        <span className='dialog-txt' style={{lineHeight: "30px", marginRight: "50px"}}>Effetti</span>
                        <input
                            id='effects-slider'
                            type="range"
                            min="0"
                            max="1"
                            step="0.01"
                            value={options.effectsVolume}
                            onChange={() => {
                                updateSliderVal("effects-slider", options.setEffectsVolume);
                            }}
                            className="custom-slider"
                        />
                    </div>

                    <CommonButton
                        id="guideBtn"
                        txt={<div className='check-container' style={{marginTop: "0px"}}>
                            <img src={guideIcon} alt="check" id='guide-btn-icon' style={{marginRight: "10px"}}/>
                            <span className='common-btn-txt'>Come giocare</span>
                        </div>}
                        color={btnColors.grey}
                        size={btnSizes.wide}
                        click={() => {
                            
                        }}
                    ></CommonButton>
                    
                    <CommonButton
                        id="clearData"
                        txt="Elimina dati"
                        color={btnColors.red}
                        size={btnSizes.wide}
                        click={() => {
                            setClearData(true);
                        }}
                    ></CommonButton>

                </div>}
                color={dialogColors.default}
                common={() => {
                    closePopup("settings-dialog", showSettings);
                }}
            ></Dialog>

            {clearData && <Dialog
                id="clear-dialog"
                title="Eliminare tutti i dati?"
                txt={<div className='groups-dialog'>
                    <span className='dialog-txt'>Sei sicuro di voler eliminare tutti i dati del gioco salvati nel dispositivo?<br></br></span>
                    <div style={{height: "10px"}}></div>
                    <span id='customWords-dialog-txt' style={{ opacity: "0.8"}}>L'applicazione sarà riavviata!</span>
                    <div style={{height: "20px"}}></div>
                </div>}
                color={dialogColors.default}
                cancel={() => {
                    closePopup("clear-dialog", setClearData);
                }}
                confirm={() => {
                    localStorage.clear();
                    window.location.reload();
                }}
                canConfirm={true}
                confirmDestructive={true}
            ></Dialog>}
            </>
        ) : (
            <></>
        )
    );
};

export default SettingsDialog;