import React, { useEffect, useCallback } from 'react';
import './Input.css';

export let inputColors = {
    "default" : "input-color-default",
    "disabled" : "input-color-disabled",
    "error" : "input-color-error",
    "done" : "input-color-done",
    "blue" : "input-color-blue",
    "light-blue": "input-color-light-blue"
}

export let inputSizes = {
    "default" : "input-size-default",
    "done" : "input-size-done",
    "team" : "input-size-team"
}

const Input = ({id, size, type="text", color, placeholder="", value=null, readOnly=false, onChange, confirm}) => {
    const cl = "input-container " + color + " " + size;

    const handleKeyPress = useCallback((event) => {
        if (event.key === "Enter") {
            const input = document.getElementById(id).querySelector("input");
            input.blur()
            confirm();
        }
    }, [confirm]);

    useEffect(() => {
        const input = document.getElementById(id).querySelector("input");
        input.value = value;

        input.focus();
        input.addEventListener("keypress", handleKeyPress);

        return () => {
            input.removeEventListener("keypress", handleKeyPress);
        };
    }, []);

    return (
        <div className={cl} id={id}>
            { !readOnly && <input className="input" type={type} placeholder={placeholder} onChange={onChange}></input>}
            { readOnly && <input className="input" type={type} readOnly={readOnly} style={{letterSpacing: 2+"px"}} onChange={onChange}></input>}
        </div>
    );
}

export default Input;