import './App.css';
import bg from './assets/images/bg.png'
import Home from './Screens/Home/Home';
import Game from './Screens/Game/Game';
import NoSleep from 'nosleep.js';
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
// import { register } from './serviceWorkerRegistration';
//imgs
import fullscreenIcon from './assets/images/fullscree_icon.png'
import cancelFullscreenIcon from './assets/images/cancel_fs_icon.png'


export let globalVars = {
    z_type: "",
    z_code: "",
    z_fullscreen: fullscreenIcon,

    //TYPE
    get type() {
        return this.z_type;
    },
    set type(type) {
        this.z_type = type;
    },

    //CODE
    get code() {
        return this.z_code;
    },
    set code(code) {
        this.z_code = code;
    },

    //FULLSCREEN
    get fullscreen() {
        return this.z_fullscreen;
    },
    set fullscreen(icon) {
        this.z_fullscreen = icon;

        let fs = document.querySelector("#fullscreen").querySelector(".common-btn-icon");
        if(fs) {
            fs.src = icon;
        }
    }
};


function App() {
  const [guide, showGuide] = useState(false);
  const [settings, showSettings] = useState(false);
  const [updating, setUpdate] = useState(false);

  const [musicVolume, setMusicVolume] = useState(1.0);
  const [effectsVolume, setEffectsVolume] = useState(1.0);


  useEffect(() =>{
    window.addEventListener('resize', scaleElements);

    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;
    if(screenWidth > screenHeight) {
        //orientamento orizzontale
        document.querySelector(".App").classList.add("normal-bg");
    } else {
        //orientamento verticale
        const imgElement = document.createElement('img');
        imgElement.src = bg;
        imgElement.className = 'buttonBg';

        // Trova l'elemento .App e aggiungi l'elemento come primo figlio
        const appElement = document.querySelector('.App');
        appElement.insertBefore(imgElement, appElement.firstChild);

    }

    window.addEventListener('fullscreenchange', function(event) {
        if (document.fullScreenElement || document.mozFullScreenElement || document.webkitFullscreenElement) {
          globalVars.fullscreen = cancelFullscreenIcon;
        } else {
            globalVars.fullscreen = fullscreenIcon;
        }
    });

    //SERVICE WORKER - CONTROLLO AGGIORNAMENTI
    /* register({
        onUpdate: (registration) => {
            if (registration && registration.waiting) {
                registration.waiting.postMessage({ type: 'SKIP_WAITING' });
                registration.waiting.addEventListener('statechange', (e) => {
                    if (e.target.state === 'activated') {
                        window.location.reload();
                    }
                });
            }
        },
        onSuccess: () => {
            setUpdate(false);
        },
        onCancel: () => {
            setUpdate(false);
        }
    }); */

    if(!localStorage.getItem("myTeams")) {
        localStorage.setItem("myTeams", JSON.stringify([]))
    }
    if(!localStorage.getItem("customWords")) {
        localStorage.setItem("customWords", JSON.stringify([]))
    }
    //localStorage.clear();

    const noSleep = new NoSleep();
    document.addEventListener('click', () => {
        noSleep.enable();
    }, { once: true });

    return () => {
        noSleep.disable();
    };
  }, []);

  const options = {
    musicVolume,
    setMusicVolume,
    effectsVolume,
    setEffectsVolume,
  }
  

  return (
    <div className="App">
      <Router>
        <Routes>
          {/* SCHERMATA HOME */}
          <Route path={"/"} element={<Home settings={settings} showSettings={showSettings} upadting={updating} options={options}/>} />
          <Route path={"/:paramCode"} element={<Home settings={settings} showSettings={showSettings} upadting={updating} options={options}/>} />

          {/* GAME */}
          <Route path="/game" element={<Game settings={settings} showSettings={showSettings} musicVolume={musicVolume} effectsVolume={effectsVolume} options={options}/>}/>

          {/* OTHER ROUTES -> HOME */}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Router>
    </div>
  );
}


export function fullScreen() {
    if (!document.fullscreenElement) {
        // Se la modalità schermo intero non è attiva, attivala
        if (document.documentElement.requestFullscreen) {
            document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) { /* Firefox */
            document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) { /* Chrome, Safari e Opera */
            document.documentElement.webkitRequestFullscreen();
        } else if (document.documentElement.msRequestFullscreen) { /* IE/Edge */
            document.documentElement.msRequestFullscreen();
        }
        globalVars.fullscreen = cancelFullscreenIcon;
    } else {
        // Se la modalità schermo intero è attiva, disattivala
        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if (document.mozCancelFullScreen) { /* Firefox */
            document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) { /* Chrome, Safari e Opera */
            document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) { /* IE/Edge */
            document.msExitFullscreen();
        }
        globalVars.fullscreen = fullscreenIcon;
    }
}

/*let play = document.querySelector("#play");
const h = window.innerHeight;
//console.log(h);
play.style.top = (337 + ((h - (337 + 90))/2)) + "px";*/

/* export const scaleElements = (canTry = true) => {
  
    try {
        // Seleziona l'elemento da scalare
        let element;
        let playBtn;
        if(window.location.pathname == "/") {
            element = document.querySelector('.home');
            playBtn = document.getElementById("play");
        }
        if(window.location.pathname == "/game") {
            element = document.querySelector('.game');
        }

        if(element) {
            const screenWidth = window.innerWidth;
            const screenHeight = window.innerHeight;
            const scaleX = screenWidth <= 500 ? screenWidth / 500 : 1;
            let scaleY = 1;

            if(screenWidth < 500) {
                scaleY = screenWidth / 500;
            }

            // Calcola la nuova altezza del contenitore in base all'altezza dell'elemento dopo la scala
            let newHeight = '100%';
            let newWidth = '100%';

            if(screenWidth < 500) {   //ridimensionamento orizzontale
                newWidth = window.innerWidth / scaleX + "px";
                newHeight = window.innerHeight / scaleY + "px";
            }



            element.style.transform = `scale(${scaleX})`; // Applica la scala proporzionale
            if(playBtn) {
                if(scaleX<1) {
                    playBtn.style.transform = `scale(1.3)`;
                } else {
                    playBtn.style.transform = `scale(1.1)`;
                }
            }

            element.style.height = newHeight;
            element.style.width = newWidth;
            //console.log("resized "+screenWidth);
            //imposta il margin dei titoli
            const newMargin = screenWidth <= 500 ? (600*50)/screenWidth + "px" : '50px';
            const title = document.querySelector(".title");
            if(title) {
                title.style.marginTop = newMargin;
            }
        }


        //home-step2 non è largo il massimo perché nessun elemento al suo interno
        //è largo abbastanza (vedi home-step4-d)
        //prova con un flex-grow 1 orizzontale oppure con un div fisso molto largo
    } catch(e) {
        console.error(e);
        if(canTry) {
            setTimeout(() => {
                scaleElements(false);
            }, 10);
        }
    }
} */

export const scaleElements = (canTry = true) => {

    try {
        // Seleziona l'elemento da scalare
        let element;
        let playBtn;
        if(window.location.pathname == "/") {
            element = document.querySelector('.home');
            playBtn = document.getElementById("play");
        }
        if(window.location.pathname == "/game") {
            element = document.querySelector('.game');
        }

        if(element) {
            const screenWidth = window.innerWidth;
            const screenHeight = window.innerHeight;
            console.log(screenHeight);
            let scaleX = screenWidth <= 500 ? screenWidth / 500 : 1;
            let scaleY = screenHeight <= 600 ? screenHeight / 600 : 1;

            if(screenWidth < 500) {
                scaleY = screenWidth / 500;
            }

            // Calcola la nuova altezza del contenitore in base all'altezza dell'elemento dopo la scala
            let newHeight = '100%';
            let newWidth = '100%';

            //IMPRECISO: QUANDO SCALA IN VERTICALE NON RIESCE AD IMPOSTARE IL WIDTH

            if(screenWidth < 500) {   //ridimensionamento orizzontale
                newWidth = window.innerWidth / scaleX + "px";
                newHeight = window.innerHeight / scaleY + "px";
            }

            if(screenHeight < 600) {   //ridimensionamento orizzontale
                newWidth = window.innerWidth / scaleY + "px";
                newHeight = window.innerHeight / scaleY + "px";
            }

            if(screenHeight < 600) {
                scaleX = scaleY;
            }
            
            element.style.transform = `scale(${scaleX})`; // Applica la scala proporzionale
            if(playBtn) {
                if(scaleX<1) {
                    playBtn.style.transform = `scale(1.3)`;
                } else {
                    playBtn.style.transform = `scale(1.1)`;
                }
            }

            element.style.height = newHeight;
            element.style.width = newWidth;
            //console.log("resized "+screenWidth);
            //imposta il margin dei titoli
            const newMargin = screenWidth <= 500 ? (600*50)/screenWidth + "px" : '50px';
            const title = document.querySelector(".title");
            if(title) {
                title.style.marginTop = newMargin;
            }
        }


        //home-step2 non è largo il massimo perché nessun elemento al suo interno
        //è largo abbastanza (vedi home-step4-d)
        //prova con un flex-grow 1 orizzontale oppure con un div fisso molto largo
    } catch(e) {
        console.error(e);
        if(canTry) {
            setTimeout(() => {
                scaleElements(false);
            }, 10);
        }
    }
}


export const errcode = {
  "ERR_GAME_FULL": "La partita è già connessa ad un pulsante.",
  "ERR_GAME_NOTFOUND": "La partita non esiste oppure è terminata.",
  "ERR_CREATE_GAME": "Non è stato possibile creare una nuova partita, riprova più tardi.",
  "ERR_START_GAME": "Non è stato possibile iniziare la partita, riprova più tardi.",
  "ERR_EDIT_TEAM": "Impossibile salvare le modifiche alla squadra.",
  "ERR_DUPL_TEAM": "Esiste già una squadra con questo nome.",
  "ERR_DUPL_GROUP": "Esiste già un gruppo con questo nome.",
  "ERR_DUPL_WORD": "La parola è già presente in questo gruppo.",
  "ERR_CUR_DISCONNECTED": "Ti sei disconnesso.\nVerifica la tua connessione di rete e riprova.",
  "ERR_HOST_DISCONNECTED": "L'host si è disconnesso.\nAccedi ad una nuova partita per continuare a giocare.",
  "ERR_BTN_DISCONNECTED": "Il pulsante si è disconnesso.\nConnettine un altro prima di continuare."
}

export default App;


