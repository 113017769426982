import React, { useEffect } from 'react';
import './List.css'
import { globalVars } from '../../App';
import { listTypes } from './List';
import { btnColors } from '../CommonButton/CommonButton';

import unchecked from "../../assets/images/unchecked_icon.png"
import checked from "../../assets/images/checked_icon.png"
import delete_icon from "../../assets/images/delete_list.png"
import doubleIcon from "../../assets/images/double_icon.png"
import indetermined_check from "../../assets/images/indeterminate_check_icon.png"



const ListItem = ({name, index, newItem, interactable, onClick, type=listTypes.normal, score, selected, enabled, onDelete, onChecked, isDouble }) => {
    
    //console.log(name);
    //console.log(newItem);
    let className = "list-item";

    if(type == listTypes.ranking && index < 3) {
        className += " final-pos-" + (index+1);
    } else {
        if(enabled || type != listTypes.myTeams) {
            className += " list-item-blue";
        } else {
            className += " " + btnColors.grey;
        }
    }

    if(type == listTypes.myTeams || type == listTypes.wordGroups || type == listTypes.customWords) {
        className += " list-item-dialog"
    } else {
        className += " list-item-" + globalVars.type
    }

    
    
    if(newItem) {
        //nuovo elemento
        className += " list-item-new";
        //console.log("nnew");
    }
    
    if(interactable) {
        className += " list-item-interactable";
    }

    const handleOnClick = () => {
        //console.log("click item", enabled);
        if(onClick && (enabled || type != listTypes.myTeams)) {
            onClick(index);
        }
    }

    const checkIcons = {
        "check_false": unchecked,
        "check_true": checked,
        "check_indt": indetermined_check
    }


    return (
        type == listTypes.ranking ? (
            <div className={className} onClick={handleOnClick}>
                <span className='place-txt' style={{marginLeft: "30px"}}>{(index + 1) + "."}</span>
                <div style={{width: "20px"}}></div>
                <span className='common-btn-txt'>{name}</span>
                <div style={{flexGrow: 1}}></div>
                <span className='common-btn-txt' style={{marginRight: "50px"}}>{score}</span>
            </div>
        ) : type == listTypes.myTeams ? (
            <div className={className} onClick={handleOnClick}>
                <img src={checkIcons["check_"+selected]} alt="check" className='list-icon' style={{marginLeft: "15px"}}/>
                <div style={{width: "15px"}}></div>
                <span className='list-item-txt-myTeams'>{name}</span>
                <div style={{flexGrow: 1}}></div>
                <img src={delete_icon} alt="check" className='list-icon-2' style={{marginRight: "20px"}} onClick={(event) => {
                    event.stopPropagation();
                    onDelete(index);
                }}/>
            </div>
        ) : type == listTypes.wordGroups ? (
            <div className={className} onClick={handleOnClick}>
                <img src={checkIcons["check_"+selected]} alt="check" className={'list-icon'+(!enabled ? "-dis" : "")} style={{marginLeft: "15px"}} onClick={(event) => {
                    event.stopPropagation();
                    onChecked(index);
                }}/>
                <div style={{width: "15px"}}></div>
                <span className='list-item-txt-myTeams'>{name}</span>
                <div style={{flexGrow: 1}}></div>
                <img src={delete_icon} alt="check" className='list-icon-2' style={{marginRight: "20px"}} onClick={(event) => {
                    //console.log("click elimina");
                    event.stopPropagation();
                    onDelete(index);
                }}/>
            </div>
        ) : type == listTypes.customWords ? (
            <div className={className} onClick={handleOnClick}>
                <img src={checkIcons["check_"+selected]} alt="check" className='list-icon' style={{marginLeft: "15px"}}/>
                <div style={{width: "15px"}}></div>
                <span className='list-item-txt-myTeams'>{name}</span>
                <div style={{flexGrow: 1}}></div>
                {isDouble && <img src={doubleIcon} alt="check" className='list-icon' style={{marginLeft: "15px"}}/>}
                <img src={delete_icon} alt="check" className='list-icon-2' style={{marginRight: "20px"}} onClick={(event) => {
                    //console.log("click elimina");
                    event.stopPropagation();
                    onDelete(index);
                }}/>
            </div>
        ) : (
            <div className={className} onClick={handleOnClick}>
                <span className='list-item-txt'>{name}</span>
            </div>
        )
    );
    
}

export default ListItem;