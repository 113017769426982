import React, { useEffect, useRef } from 'react';
import './Game.css';
import './Animations.css'
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { globalVars, scaleElements } from '../../App';
//images
import settingsIcon from '../../assets/images/settings_icon.png'
import logo from "../../assets/images/logo.png"
import fullscreenIcon from '../../assets/images/fullscree_icon.png'
//objs
import WaitTeams from './Screens/WaitTeams';
import CreateTeams from './Screens/CreateTeams';
import CommonButton,  {btnColors, btnSizes} from '../../Objects/CommonButton/CommonButton';
import { fullScreen, errcode } from '../../App';
import { addNewCommandsListener, addPathListener, addPresence, getValue, keyExists, onClientDisconnected, onPeerDisconnected, pushValue, removePath, setValue } from '../../firebase';
import Loading, { loadingColors, loadingSpeeds } from '../../Objects/Loading/Loading';
import Dialog from '../../Objects/Dialog/Dialog';
import QRGenerator from '../../Objects/QRGenerator/QRGenerator';
import Input, { inputColors, inputSizes } from '../../Objects/Input/Input';
import Game_display, { gameStatus_display } from './Screens/Game_display';
import CutScene from './Screens/CutScene';
import Game_button, { gameStatus_button } from './Screens/Game_button';
import Title from '../../Objects/Title/Title';
import AudioComponent from '../../AudioComponent';
import LoadingDialog from '../../Objects/LoadingDialog/LoadingDialog';
import SettingsDialog from '../../Objects/SettingsDialog';

//Words
import words_list from "../../assets/words.json"
import doubleWords_list from "../../assets/double_words.json"

//Audio
import showScore_last_audio from "../../assets/musics/showScore_last.mp3";
import AppLogo from '../../Objects/AppLogo/appLogo';
import List, { listTypes } from '../../Objects/List/List';

//contiene i comandi e le costanti firebase
export let firebaseCommands = {
    creator: "caller-creator",
    reciver: "caller-reciver",
    startGame: {
        name: "startGame",
    },
    startVideo: {
        name: "startVideo",
    },
    skipVideo: {
        name: "skipVideo",
    },
    showButton: {
        name: "showButton",
    },
    generateWord: {
        name: "generateWord",
    },
    startGuessing: {
        name: "startGuessing",
    },
    wrong: {
        name: "wrong",
    },
    correct: {
        name: "correct",
    },
    skip: {
        name: "skip"
    },
    turnEnded: {
        name: "turnEnded"
    },
    doubleWord: {
        name: "doubleWord"
    },
    showResults: {
        name: "showResults"
    },
    cancelAnswer: {
        name: "cancelAnswer"
    },
    showEnd: {
        name: "showEnd"
    },
    endGame: {
        name: "endGame"
    },
    replay: {
        name: "replay"
    }
};

const Game = ({ settings, showSettings, options, musicVolume, effectsVolume  }) => {
    const navigate = useNavigate();
    const [error, setError] = useState({
        show: false
    });
    const [loading, setLoading] = useState(false);

    //contiene le squadre caricate dal database
    const [teams, setTeams] = useState([]);

    //contiene le parole normali
    const [words, setWords] = useState([]);
    const [customWords, setCustomWords] = useState([]);

    //contiene le parole doppie
    const [doubleWords, setDoubleWords] = useState([]);
    const [customDoubleWords, setCustomDoubleWords] = useState([]);

    //utilizza parole standard
    const [useStandards, setUseStandards] = useState(true);

    //usa parole personalizzate
    const [useCustoms, setUseCustoms] = useState(false);

    //percentuale parole standard/personalizzate
    const [percent, setPercent] = useState(1);

    //tiene conto delle parole già mostrate
    //condiviso per tutte le partite, meno probabilità di pescare le stesse parole
    const [readKeys, setReadKeys] = useState([]);
    const [readDoubleKeys, setReadDoubleKeys] = useState([]);
    const [readCustomKeys, setCustomReadKeys] = useState([]);
    const [readCustomDoubleKeys, setCustomReadDoubleKeys] = useState([]);

    //controlla se è stato forzato l'uso delle standard
    const [forceStandards, setForceStandards] = useState(false);
    const [forceDoubleStandards, setForceDoubleStandards] = useState(false);

    //schermata attesa squadre
    const [waitTeams, wait_changeTeams] = useState({
        enabled: (globalVars.type == "d" ? true : false)
    });
    

    //schermata creazione squadre
    const [createTeams, create_changeTeams] = useState({
        enabled: (globalVars.type == "b" ? true : false),
    })

    const [game, changeGame] = useState({
        enabled: false,
        cur: -1,          //standard +1, la prima sarà 0
        isLast: false
    });

    const [intro, setIntro] = useState(true);
    const [fadeOut, setFadeOut] = useState(false);
    const [showCutScene, setCutScene] = useState(false);
    const [canSkip, setCanSkip] = useState(false);
    const [isLastSkip, setLastSkip] = useState(false);

    const waitTeamsRef = useRef(waitTeams);
    const createTeamsRef = useRef(createTeams);
    const gameRef = useRef(game);
    const teamsRef = useRef(teams);
    const client = useRef(null), peer = useRef(null), commands = useRef(null), music = useRef(null), effects = useRef(null); 
    let showScore_last = useRef(null);

    useEffect(() => {
        waitTeamsRef.current = waitTeams;
    }, [waitTeams]);

    useEffect(() => {
        createTeamsRef.current = createTeams;
    }, [createTeams]);

    useEffect(() => {
        gameRef.current = game;
    }, [game]);

    useEffect(() => {
        teamsRef.current = teams;
    }, [teams]);

    let teams_ordered = [];
    if(teamsRef.current) {
        teams_ordered = teamsRef.current.slice();
        teams_ordered.sort((a,b) => b.score - a.score);
    }


    useEffect(() => {
        if(error.show) {
            setTimeout(() => {
                let d = document.querySelector("#global_error");
                d.style.opacity = null;
                d.classList.add("fade-in");
            }, 10);
        }
    }, [error])

    //Controlla le disconnessioni
    //Imposta i riferimenti funzione -> comando firebase
    useEffect(() => {

        let iubendaFloat = document.querySelector(".iubenda-tp-btn");
        if(iubendaFloat) {
            iubendaFloat.style.pointerEvents = "none"
            iubendaFloat.style.opacity = "0";
        }

        if(globalVars.code == "" || globalVars.type == "") {
            //se non è in una stanza
            //non è passato dalla home, riportalo
            navigate("/");
        } else {
            setListeners();
        }

        scaleElements();
        if(globalVars.type == "b") {
            setLoading(true);
            (async () => {
                await loadTeams();
                setLoading(false);
            })();
        }

        if(globalVars.type == "d") {
            setVolumesListeners();
        }
        //console.log("use effect");

        //imposta riferimenti ai comandi
        firebaseCommands.startGame.call = startGame;
        firebaseCommands.startVideo.call = startVideo;
        firebaseCommands.skipVideo.call = skipVideo;
        firebaseCommands.showButton.call = showButton;
        firebaseCommands.generateWord.call = generateWord;
        firebaseCommands.startGuessing.call = startGuessing;
        firebaseCommands.wrong.call = wrong;
        firebaseCommands.correct.call = correct;
        firebaseCommands.skip.call = skip;
        firebaseCommands.turnEnded.call = turnEnded;
        firebaseCommands.doubleWord.call = doubleWord;
        firebaseCommands.showResults.call = showResults;
        firebaseCommands.cancelAnswer.call = cancelAnswer;
        firebaseCommands.showEnd.call = showEnd;
        firebaseCommands.endGame.call = endGame;
        firebaseCommands.replay.call = replay;
        

        async function loadTeams() {
            if(await keyExists(globalVars.code + "/teams")) {
                let list = await getValue(globalVars.code + "/teams");
                setTeams(list);

                await checkForAutoStart();
            }
        }

        async function setListeners() {
            //aggiungi la presenza nella partita
            addPresence();
            //aggiungi il listener per la disonnessione del client
            if(!client.current) {
                client.current = await onClientDisconnected(() => {
                    setError({
                        show: true,
                        txt: errcode["ERR_CUR_DISCONNECTED"],
                    });

                    if(globalVars.type == "d") {
                        changeGame(prevState => ({
                            ...prevState,
                            prevStep: gameRef.current.step,
                            step: gameStatus_display.pause
                        }));
                    }
                });
            }
            
            //aggiungi il listener per la disonnessione del client opposto
            if(globalVars.type == "d") {
                if(!peer.current) {
                    peer.current = await onPeerDisconnected(() => {
                        //se si disconnette il pulsante, dai la possibilità di entrare di nuovo
                        const errorMessage = (
                            <>
                            {errcode["ERR_BTN_DISCONNECTED"]}
                            
                            <div className='home-step4-d-1'>
                                <div className='dialog-cont-1'>
                                    <QRGenerator data={"https://intesavincente.web.app/"+globalVars.code} className={"display-qr"} />
                                    <Input
                                        id="copyCode"
                                        size={inputSizes["done"]}
                                        color={inputColors["done"]}
                                        value={globalVars.code}
                                        readOnly={true}
                                    />
                                </div>
                                <div className="home-step4-d-3" style={{top: 0+"px"}}>
                                    <Loading id="waiting-loading" color={loadingColors["white"]} speed={loadingSpeeds["slow"]}/>
                                    <div style={{width: 20+"px"}}></div>
                                    <span className="dialog-txt">Attesa dispositivo...</span>
                                </div>
                            </div>
                            
                            </>
                        );
                        setError({
                            show: true,
                            txt: errorMessage,
                            width: "800px"
                        });

                        //attendi nuova connessione
                        let newBtn_listener = addPathListener("button", (val) => {
                            if(val=="online") {
                                closePopup();
                                if (typeof newBtn_listener === 'function') {
                                    newBtn_listener();
                                }
                                changeGame(prevState => ({
                                    ...prevState,
                                    step: gameStatus_display.resume
                                }));

                                if(gameRef.current.prevStep == gameStatus_display.showResults && gameRef.current.step == gameStatus_display.pause) {
                                    //se non è cambiato lo stato durante la pausa (se l'animazione si è bloccata oppure è ancora in tempo)
                                    firebaseCommands.showResults.call(firebaseCommands.reciver, true, 0);
                                }

                                if(gameRef.current.prevStep == gameStatus_display.lastResults && gameRef.current.step == gameStatus_display.pause) {
                                    //se non è cambiato lo stato durante la pausa (se l'animazione si è bloccata oppure è ancora in tempo)
                                    nextTeam();
                                }
                                
                                if(gameRef.current.prevStep == gameStatus_display.endGame && gameRef.current.step == gameStatus_display.pause) {
                                    //se non è cambiato lo stato durante la pausa (se l'animazione si è bloccata oppure è ancora in tempo)
                                    changeGame(prevState => ({
                                        ...prevState,
                                        step: gameStatus_display.endGame
                                    }));

                                    setTimeout(() => {
                                        const screen1 = document.getElementById("final-screen1");
                                        const screen2 = document.getElementById("final-screen2");
                                        screen1.display = "none";
                                        screen2.style.display = "flex";
                                        screen2.classList.add("fadein");
                                    }, 100);
                                }

                            }
                        });

                        changeGame(prevState => ({
                            ...prevState,
                            prevStep: gameRef.current.step,
                            step: gameStatus_display.pause
                        }));

                    });
                }
            }
            if(globalVars.type == "b") {
                if(!peer.current) {
                    peer.current = await onPeerDisconnected(() => {
                        setError({
                            show: true,
                            txt: errcode["ERR_HOST_DISCONNECTED"]
                        });
                    });
                }
            }

            //aggiungi il listener per i comandi
            if(!commands.current) {
                //console.log("added command listener "+commands.current);
                commands.current = await addNewCommandsListener();
            }
            //console.log("listener added");
        }

        async function checkForAutoStart() {
            //se la partita è già iniziata
            let status = await getValue(globalVars.code + "/status");
            if(status != 0) {
                //avvia manualmente il gioco skippando la cutscene
                wait_changeTeams(prevState => ({
                    ...prevState,
                    enabled: false
                }));
                create_changeTeams(prevState => ({
                    ...prevState,
                    enabled: false
                }));
                setFadeOut(false);
                let game_btn = await getValue(globalVars.code + "/btn_status/game");
                
                
                if(game_btn) {
                    const curScore = await getValue(globalVars.code + "/btn_status/curScore");
                    game_btn.loadCurScore = curScore;
                    //console.log("LOAD TEAMS "+ JSON.stringify(game_btn));
                    changeGame(game_btn);
                } else {
                    showButton(firebaseCommands.reciver, status);
                }
                
                //changeGame(game_btn);
                //showButton(firebaseCommands.reciver, status);
            }
        }

        async function setVolumesListeners() {
            /* const [music, effects] = await Promise.all([
                getValue(globalVars.code+"/music"),
                getValue(globalVars.code+"/effects")
            ])
            options.setMusicVolume(music);
            options.setEffectsVolume(effects); */

            if(!music.current) {
                music.current = await addPathListener("music",(val) => {
                    if(val) {
                        options.setMusicVolume(val);
                    }
                });
            }
            if(!effects.current) {
                effects.current = await addPathListener("effects",(val) => {
                    if(val) {
                        options.setEffectsVolume(val);
                    }
                });
            }
        }

        return () => {
            // Rimuovi il listener quando il componente si smonta
            if(typeof client.current === 'function') {
                client.current();
                client.current = null;
                //console.log("detatched client");
            }
            if(typeof peer.current === 'function') {
                peer.current();
                peer.current = null;
                //console.log("detatched peer");
            }
            if(typeof commands.current === 'function') {
                commands.current();
                commands.current = null;
                //console.log("detatched commands");
            }

            if(typeof music.current === 'function') {
                music.current();
                music.current = null;
                //console.log("detatched music");
            }

            if(typeof effects.current === 'function') {
                effects.current();
                effects.current = null;
                //console.log("detatched effects");
            }
            
            //console.log(client);
            //console.log(peer);
            //console.log(commands);
        };
    }, []);

    //avvia il gioco (bottone)
    useEffect(() => {
        if(createTeams.done && createTeams.enabled) {
            startGame(firebaseCommands.creator);
        }
    }, [createTeams]);
    
    const closePopup = () => {      //CHIUDE MESSAGGIO DI ERRORE
        let d = document.querySelector("#global_error");
        d.classList.remove("fade-in")
        d.style.opacity = null;
        d.classList.add("fade-out");

        setTimeout(() => {
            setError({
                show: false
            });
        }, 100);
    }

    const nextTeam = () => {
        //setCutScene(false);
        setIntro(false);    //la intro è solo la prima volta
        const limit = gameRef.current.cur == teamsRef.current.length - 1;
        //console.log("next team", gameRef.current.cur, teamsRef.current.length);

        //controlla se hanno giocato tutte le squadre
        if(!gameRef.current.isLast) {   //nega la condizione
            changeGame(prevState => ({
                ...prevState,
                step: 1,
                cur: !limit ? gameRef.current.cur + 1 : 0
            }));

            setTimeout(() => {
                const bottom = document.querySelector(".bottom");
                bottom.classList.remove("fadeout");
                bottom.classList.add("fadein");
            }, 2000);
        } else {
            //ANIMAZIONE FINALE
            firebaseCommands.showButton.call(firebaseCommands.creator, gameStatus_button.results);

            changeGame(prevState => ({
                ...prevState,
                step: gameStatus_display.lastResults
            }));

            setTimeout(() => {
                let first = true;
                let position = document.getElementById("final-position");
                let position_txt = position.querySelector("span");
                const team = document.getElementById("final-team");
                const team_txt = team.querySelector("span");
                const score = document.getElementById("final-score");
                const score_txt = score.querySelector("span");
                const screen1 = document.getElementById("final-screen1");
                const screen2 = document.getElementById("final-screen2");

                //lista ordinata temporanea
                const teams_ordered = teamsRef.current.slice();
                teams_ordered.sort((a,b) => b.score - a.score);

                setTimeout(async () => {
                    position.classList.add("pop_in");
                    if(teamsRef.current.length >= 3) {
                        await showPos(3);
                    }
                    if(teamsRef.current.length >= 2) {
                        await showPos(2);
                    }
                    if(teamsRef.current.length >= 1) {
                        await showPos(1);
                    }
                    screen1.classList.add("fadeout");
                    await delay(500);
                    screen1.display = "none";
                    screen2.style.display = "flex";
                    screen2.classList.add("fadein");
                    firebaseCommands.showEnd.call(firebaseCommands.creator);

                    if(gameRef.current.step != gameStatus_display.pause) {
                        changeGame(prevState => ({
                            ...prevState,
                            step: gameStatus_display.endGame
                        }));
                    }
                }, 500);
                
                async function showPos(pos) {
                    position_txt.innerHTML = pos+"°";
                    position.classList.add("final-pos-"+pos);
                    await delay(first ? 1000 : 1500);
                    first = false;

                    position.classList.remove("pop_in");                   
                    position.style.opacity = 1;

                    score.style.display = "flex";
                    score_txt.innerHTML = teams_ordered[pos-1].score;
                    team_txt.innerHTML = teams_ordered[pos-1].name;
                    score.classList.add("final-moveRight");
                    position.classList.add("final-moveLeft");
                    team.classList.add("pop_in");
                    await delay(1500);
                    
                    team.classList.remove("pop_in");
                    team.classList.add("pop_out");
                    score.classList.remove("final-moveRight");
                    position.classList.remove("final-moveLeft");
                    score.classList.add("final-reverseRight");
                    position.classList.add("final-reverseLeft");

                    if(pos != 1) {
                        setTimeout(() => {
                            team.classList.remove("pop_out");
                            score.classList.remove("final-reverseRight");
                            position.classList.remove("final-reverseLeft");
                            position.classList.remove("final-pos-"+pos);
                        }, 500);
                        
                    } else {
                        setTimeout(() => {
                            team.classList.remove("pop_out");
                        }, 500);
                    }
                }

                function delay(ms) {
                    return new Promise(resolve => setTimeout(resolve, ms));
                }
            }, 100);
            
        }
        
    }

    const animateScore = (x, y, s) => {
        const span = document.getElementById("animate-score"); // Assicurati di avere un elemento con id 'mySpan'
        const interval = setInterval(() => {
            _a();
        }, s/y);
        
        _a();

        function _a() {
            try {
                span.textContent = x;
                x++;
                if (x > y) {
                    if(interval) {
                        clearInterval(interval);
                    }
                }
            } catch (e) {
                return;
            }
        }
    }

    const goHome = () => {
        if(globalVars.type == "b") {
            removePath(globalVars.code+"/button");
        }
        if(globalVars.type == "d") {
            removePath(globalVars.code+"/display");
        }
        navigate("/");
    }

    /*
        FUNZIONI COMANDI FIREBASE
    */
    const startGame = (caller) => {
        //modificare il nome della funzione
        const self = firebaseCommands["startGame"];

        if (caller == firebaseCommands.creator) {
            /*
                MODIFICA INVIO COMANDO
            */
            pushValue(globalVars.code + "/commands", {
                commandName: self.name,     //nome comando
                sentBy: globalVars.type,    //inviato da display/bottone
                executeSelf: true           //deve eseguirlo anche chi ha inviato il comando
            }, true);

            setValue(globalVars.code + "/status", 1);

        } else {
            /*
                MODIFICA AZIONE COMANDO
            */
            //console.log("STARTGAME");
            setCanSkip(false);
            setCutScene(true);
            
            if(globalVars.type == "b") {
                setFadeOut(false);
            }

            setTimeout(() => {
                changeGame(prevState => ({
                    ...prevState,
                    step: 0,
                    enabled: false
                }));
                wait_changeTeams(prevState => ({
                    ...prevState,
                    enabled: false
                }));
                create_changeTeams(prevState => ({
                    ...prevState,
                    enabled: false
                }));

                if(globalVars.type == "d") {
                    //verifica quali parole deve utilizzare
                    (async () => {
                        try {
                            const [
                                useStandards,
                                useCustoms,
                                w_forceStd,
                                dw_forceStd,
                                percent,
                                w_list,
                                double_list,
                            ] = await Promise.all([
                                getValue(globalVars.code + "/useStandards"),
                                getValue(globalVars.code+"/useCustoms"),
                                getValue(globalVars.code + "/w_forceStd"),
                                getValue(globalVars.code + "/dw_forceStd"),
                                getValue(globalVars.code + "/percent"),
                                getValue(globalVars.code + "/words").then(JSON.parse),
                                getValue(globalVars.code + "/doubleWords").then(JSON.parse)
                            ]);
                            
                            setPercent(percent);
                            setForceStandards(w_forceStd);
                            setForceDoubleStandards(dw_forceStd);
                            
                            if(w_forceStd || useStandards) {
                                shuffleArray(words_list);
                                setWords(words_list);
                            }
                            if(dw_forceStd || useStandards) {
                                shuffleArray(doubleWords_list);
                                setDoubleWords(doubleWords_list);
                            }

                            if(useCustoms) {
                                let w = [];
                                let dw = [];
                                //console.log("customs");
                                //console.log(w_list, double_list);

                                w = [...new Set([...new Set(w_list), ...w])];
                                dw = [...new Set([...new Set(double_list), ...dw])];
                                shuffleArray(w);
                                shuffleArray(dw);

                                setCustomWords(w);
                                setCustomDoubleWords(dw);
                            }
                        } catch (e) {
                            setError({
                                show: true,
                                txt: errcode["ERR_START_GAME"],
                            });
                        }

                        function shuffleArray(array) {
                            for (let i = array.length - 1; i > 0; i--) {
                                const j = Math.floor(Math.random() * (i + 1));
                                [array[i], array[j]] = [array[j], array[i]];
                            }
                        }
                    })();

                    setTimeout(() => {
                        changeGame(prevState => ({
                            ...prevState,
                            enabled: true,
                            step: 0
                        }));
                    }, 500);
                    
                    const bottom = document.querySelector(".bottom");
                    if(bottom) {
                        bottom.classList.add("fadeout");
                        document.querySelector(".App").classList.add("stage");
                    }

                    const buttonBg = document.querySelector(".buttonBg");
                    if(buttonBg) {
                        buttonBg.remove();
                    }
                }

                //console.log("game changed"); 
            }, 500);

            //FINE
        }
    }

    const startVideo = (caller, isLast) => {
        //modificare il nome della funzione
        const self = firebaseCommands["startVideo"];
        if (caller == firebaseCommands.creator) {
            /*
                MODIFICA INVIO COMANDO
            */
            pushValue(globalVars.code + "/commands", {
                commandName: self.name,     //nome comando
                sentBy: globalVars.type,    //inviato da display/bottone
                executeSelf: false,           //deve eseguirlo anche chi ha inviato il comando
                isLast
            }, true);

        } else {
            /*
                MODIFICA AZIONE COMANDO
            */
            //console.log("STARTVIDEO");
            console.log(isLast);

            if(globalVars.type == "b") {
                setLastSkip(isLast);
                setCanSkip(true);
            }
            
            //FINE
        }
    }

    const skipVideo = (caller) => {
        //modificare il nome della funzione
        const self = firebaseCommands["skipVideo"];

        if (caller == firebaseCommands.creator) {
            /*
                MODIFICA INVIO COMANDO
            */
            pushValue(globalVars.code + "/commands", {
                commandName: self.name,     //nome comando
                sentBy: globalVars.type,    //inviato da display/bottone
                executeSelf: false           //deve eseguirlo anche chi ha inviato il comando
            }, true);

        } else {
            /*
                MODIFICA AZIONE COMANDO
            */
            //console.log("SKIPvIDEO");

            if(globalVars.type == "d") {
                setCanSkip(true);
                //console.log(gameRef.current.isLast);
                
                if(gameRef.current.isLast) {
                    console.log("skipped");
                    
                    showScore_last.current.currentTime = 18.58;
                }
            }
            
            //FINE
        }
    }

    const showButton = (caller, status=null) => {
        //modificare il nome della funzione
        const self = firebaseCommands["showButton"];

        if (caller == firebaseCommands.creator) {
            /*
                MODIFICA INVIO COMANDO
            */
            pushValue(globalVars.code + "/commands", {
                commandName: self.name,     //nome comando
                sentBy: globalVars.type,    //inviato da display/bottone
                executeSelf: false,           //deve eseguirlo anche chi ha inviato il comando
                status
            }, true);

        } else {
            /*
                MODIFICA AZIONE COMANDO
            */
            //console.log("SHOWBUTTON");

            setFadeOut(true);

            changeGame(prevState => ({
                ...prevState,
                enabled: true,
                step: status || 1,
                last: false,
                skips: 3,
                double: 2,
                useDouble: false,
                cur: -1,    //standard +1, la prima sarà 0
            }));
            
            //FINE
        }
    }

    const generateWord = (caller) => {
        //modificare il nome della funzione
        const self = firebaseCommands["generateWord"];

        if (caller == firebaseCommands.creator) {
            /*
                MODIFICA INVIO COMANDO
            */
            pushValue(globalVars.code + "/commands", {
                commandName: self.name,     //nome comando
                sentBy: globalVars.type,    //inviato da display/bottone
                executeSelf: false           //deve eseguirlo anche chi ha inviato il comando
            }, true);

        } else {
            /*
                MODIFICA AZIONE COMANDO
            */
            //console.log("GENERATEWORD");

            changeGame(prevState => ({
                ...prevState,
                step: gameStatus_display.newWord
            }))
            
            //FINE
        }
    }

    const startGuessing = (caller) => {
        //modificare il nome della funzione
        const self = firebaseCommands["startGuessing"];

        if (caller == firebaseCommands.creator) {
            /*
                MODIFICA INVIO COMANDO
            */
            pushValue(globalVars.code + "/commands", {
                commandName: self.name,     //nome comando
                sentBy: globalVars.type,    //inviato da display/bottone
                executeSelf: false           //deve eseguirlo anche chi ha inviato il comando
            }, true);

        } else {
            /*
                MODIFICA AZIONE COMANDO
            */
            //console.log("STARTGUESSING");

            changeGame(prevState => ({
                ...prevState,
                step: gameStatus_display.guessWord
            }))
            
            //FINE
        }
    }; 

    const wrong = (caller) => {
        //modificare il nome della funzione
        const self = firebaseCommands["wrong"];

        if (caller == firebaseCommands.creator) {
            /*
                MODIFICA INVIO COMANDO
            */
            pushValue(globalVars.code + "/commands", {
                commandName: self.name,     //nome comando
                sentBy: globalVars.type,    //inviato da display/bottone
                executeSelf: false           //deve eseguirlo anche chi ha inviato il comando
            }, true);

        } else {
            /*
                MODIFICA AZIONE COMANDO
            */
            //console.log("WRONG");
            if(gameRef.current.step == gameStatus_display.guessWord || gameRef.current.step == gameStatus_display.lastGuess) {
                changeGame(prevState => ({
                    ...prevState,
                    step: gameStatus_display.wrong
                }));
            }
            
            
            //FINE
        }
    }

    const correct = (caller) => {
        //modificare il nome della funzione
        const self = firebaseCommands["correct"];

        if (caller == firebaseCommands.creator) {
            /*
                MODIFICA INVIO COMANDO
            */
            pushValue(globalVars.code + "/commands", {
                commandName: self.name,     //nome comando
                sentBy: globalVars.type,    //inviato da display/bottone
                executeSelf: false           //deve eseguirlo anche chi ha inviato il comando
            }, true);

        } else {
            /*
                MODIFICA AZIONE COMANDO
            */
            //console.log("CORRECT");

            if(gameRef.current.step == gameStatus_display.guessWord || gameRef.current.step == gameStatus_display.lastGuess) {
                changeGame(prevState => ({
                    ...prevState,
                    step: gameStatus_display.correct
                }));
            }
            
            //FINE
        }
    }

    const skip = (caller) => {
        //modificare il nome della funzione
        const self = firebaseCommands["skip"];

        if (caller == firebaseCommands.creator) {
            /*
                MODIFICA INVIO COMANDO
            */
            pushValue(globalVars.code + "/commands", {
                commandName: self.name,     //nome comando
                sentBy: globalVars.type,    //inviato da display/bottone
                executeSelf: false           //deve eseguirlo anche chi ha inviato il comando
            }, true);

        } else {
            /*
                MODIFICA AZIONE COMANDO
            */
            //console.log("SKIP");

            if(gameRef.current.step == gameStatus_display.guessWord || gameRef.current.step == gameStatus_display.lastGuess) {
                changeGame(prevState => ({
                    ...prevState,
                    step: gameStatus_display.skip
                }));
            }
            
            //FINE
        }
    }

    const turnEnded = (caller) => {
        //modificare il nome della funzione
        const self = firebaseCommands["turnEnded"];

        if (caller == firebaseCommands.creator) {
            /*
                MODIFICA INVIO COMANDO
            */
            pushValue(globalVars.code + "/commands", {
                commandName: self.name,     //nome comando
                sentBy: globalVars.type,    //inviato da display/bottone
                executeSelf: false           //deve eseguirlo anche chi ha inviato il comando
            }, true);

        } else {
            /*
                MODIFICA AZIONE COMANDO
            */
            //console.log("TURNENDED");

            changeGame(prevState => ({
                ...prevState,
                step: gameStatus_button.guessing,
                last: true
            }));
            
            //FINE
        }
    }

    const doubleWord = (caller) => {
        //modificare il nome della funzione
        const self = firebaseCommands["doubleWord"];

        if (caller == firebaseCommands.creator) {
            /*
                MODIFICA INVIO COMANDO
            */
            pushValue(globalVars.code + "/commands", {
                commandName: self.name,     //nome comando
                sentBy: globalVars.type,    //inviato da display/bottone
                executeSelf: false           //deve eseguirlo anche chi ha inviato il comando
            }, true);

        } else {
            /*
                MODIFICA AZIONE COMANDO
            */
            //console.log("DOUBLEWORD");

            changeGame(prevState => ({
                ...prevState,
                step: gameStatus_display.double
            }));
            
            //FINE
        }
    }
    
    const cancelAnswer = (caller) => {
        //modificare il nome della funzione
        const self = firebaseCommands["cancelAnswer"];

        if (caller == firebaseCommands.creator) {
            /*
                MODIFICA INVIO COMANDO
            */
            pushValue(globalVars.code + "/commands", {
                commandName: self.name,     //nome comando
                sentBy: globalVars.type,    //inviato da display/bottone
                executeSelf: false           //deve eseguirlo anche chi ha inviato il comando
            }, true);

        } else {
            /*
                MODIFICA AZIONE COMANDO
            */
            //console.log("CANCELANSWER");

            changeGame(prevState => ({
                ...prevState,
                step: gameStatus_button.idle
            }));
            
            //FINE
        }
    }

    const showResults = (caller, executeSelf=true, wait=0) => {
        //modificare il nome della funzione
        const self = firebaseCommands["showResults"];

        if (caller == firebaseCommands.creator) {
            /*
                MODIFICA INVIO COMANDO
            */
            pushValue(globalVars.code + "/commands", {
                commandName: self.name,     //nome comando
                sentBy: globalVars.type,    //inviato da display/bottone
                executeSelf,           //deve eseguirlo anche chi ha inviato il comando
                wait
            }, true);

        } else {
            /*
                MODIFICA AZIONE COMANDO
            */
            //console.log("SHOWRESULTS");

            setTimeout(() => {
                if (globalVars.type == "d") {
                    if(gameRef.current.step != gameStatus_display.pause) {

                        let isLast = gameRef.current.cur == teamsRef.current.length - 1
                        changeGame(prevState => ({
                            ...prevState,
                            step: gameStatus_display.showResults,
                            isLast
                        }));

                        if(isLast) {
                            showScore_last.current.currentTime = 0;
                            showScore_last.current.play();
                        }

                        try {
                            const bottom = document.querySelector(".bottom");
                            if (bottom) {
                                bottom.classList.add("fadeout");
                            }

                            setTimeout(() => {
                                animateScore(0, teamsRef.current[gameRef.current.cur].score, 2500);
                            }, 300);
                            

                            setTimeout(() => {
                                try {
                                    //lampeggio due volte veloce con colore blu
                                    //poi cambia il titolo, fade della squadra singola
                                    //fade lista di squadre e ingresso scalare da destra
                                    const team = document.getElementById("scoreAnim-container");

                                    let wt = 100;

                                    //lampeggio
                                    setTimeout(() => {
                                        try {
                                            team.classList.add(inputColors.blue);
                                        } catch (e) {
                                            return;
                                        }
                                        

                                        setTimeout(() => {
                                            try {
                                                team.classList.remove(inputColors.blue);
                                            } catch (e) {
                                                return;
                                            }

                                            setTimeout(() => {
                                                try {
                                                    team.classList.add(inputColors.blue);
                                                } catch (e) {
                                                    return;
                                                }

                                                setTimeout(() => {
                                                    try {
                                                        team.classList.remove(inputColors.blue);
                                                    } catch (e) {
                                                        return;
                                                    }
                                                    
                                                }, wt);
                                            }, wt);
                                        }, wt);
                                    }, wt);
                                    
                                    
                                    setTimeout(() => {
                                        try {
                                            //cambio testo titolo
                                            const title = document.querySelector(".title").querySelector(".title-txt");

                                            title.classList.add("fadeout");
                                            setTimeout(() => {
                                                try {
                                                    title.innerHTML = "Piazzamento della squadra";
                                                    title.classList.remove("fadeout");
                                                    title.classList.add("fadein");
                                                    setTimeout(() => {
                                                        title.classList.remove("fadein");
                                                    }, 300);
                                                } catch (e) {
                                                    return;
                                                }
                                                
                                            }, 400);

                                            //lista ordinata temporanea
                                            const teams_ordered = teamsRef.current.slice();
                                            teams_ordered.sort((a,b) => b.score - a.score);

                                            //fade out team
                                            team.classList.add("fadeout");
                                            setTimeout(() => {
                                                try {
                                                    team.style.display = "none";
                                                    //console.log("NEW ELEMENT");
                                                    //ingresso lista di squadre
                                                    let i = getOrderedIndex();
                                                    let elements = 0;

                                                    if(i == teams_ordered.length -1) {
                                                        i -= 3;
                                                    } else {
                                                        i -= 2;
                                                    }
                                                    
                                                    while(i < teams_ordered.length && elements < 4) {
                                                        if(i >= 0) {
                                                            //console.log("NEW ELEMENT");
                                                            const element = document.getElementById("placeAnim-"+(elements+1));
                                                            const name = document.getElementById("placeAnim-"+(elements+1)+"-name");
                                                            const score = document.getElementById("placeAnim-"+(elements+1)+"-score");
                                                            const place = document.getElementById("placeAnim-"+(elements+1)+"-place");

                                                            //console.log("n1: ",teams_ordered[i].name);
                                                            //console.log("n2: ", gameRef.current.cur);
                                                            if(teams_ordered[i].name == teamsRef.current[gameRef.current.cur].name) {
                                                                element.classList.add("common-btn-orange");
                                                            } else {
                                                                element.classList.add("common-btn-blue");
                                                            }
                                                            
                                                            place.innerHTML = (i+1)+".";
                                                            name.innerHTML = teams_ordered[i].name;
                                                            score.innerHTML = teams_ordered[i].score;
                                                            
                                                            setTimeout(() => {
                                                                try {
                                                                    element.classList.remove("placeAnim");
                                                                    element.classList.add("enterRight");
                                                                } catch (e) {
                                                                    return;
                                                                }
                                                            }, 50 * elements);
                                                            
                                                            elements++;
                                                        }
                                                        i++;
                                                    }
                                                    
                                                    //fade out e avvio cutscene
                                                    if(isLast) {
                                                        setTimeout(() => {
                                                            firebaseCommands.startGame.call(firebaseCommands.creator);
                                                        }, 3300);
                                                    } else {
                                                        setTimeout(() => {
                                                            firebaseCommands.startGame.call(firebaseCommands.creator);
                                                        }, 3300);
                                                    }
                                                    
                                                    function getOrderedIndex() {
                                                        for (let i = 0; i < teams_ordered.length; i++) {
                                                            if (teams_ordered[i].name == teamsRef.current[gameRef.current.cur].name) {
                                                                return i;
                                                            }
                                                        }
                                                        return -1;
                                                    }
                                                } catch (e) {
                                                    return;
                                                }
                                                
                                            }, 400);
                                        } catch (e) {
                                            return;
                                        }
                                        
                                    }, 1000);

                                } catch (e) {
                                    return;
                                }

                            }, 2700);
                        } catch (e) {
                            //console.log(e);
                            return;
                            //utente disconnesso
                            //viene modificato game.step
                            //quando l'utente si riconnette, richiama manualmente la funzione
                        }
                    } else {
                        changeGame(prevState => ({
                            ...prevState,
                            prevStep: gameStatus_display.showResults
                        }));
                    }
                    
                }
            }, wait);

            if (globalVars.type == "b") {
                changeGame(prevStatus => ({
                    ...prevStatus,
                    step: gameStatus_button.results,
                }));
            }
            //FINE
        }
    }

    const showEnd = (caller) => {
        //modificare il nome della funzione
        const self = firebaseCommands["showEnd"];

        if (caller == firebaseCommands.creator) {
            /*
                MODIFICA INVIO COMANDO
            */
            pushValue(globalVars.code + "/commands", {
                commandName: self.name,     //nome comando
                sentBy: globalVars.type,    //inviato da display/bottone
                executeSelf: false           //deve eseguirlo anche chi ha inviato il comando
            }, true);

        } else {
            /*
                MODIFICA AZIONE COMANDO
            */
            //console.log("SHOWEND");

            changeGame(prevState => ({
                ...prevState,
                enabled: true,
                step: gameStatus_button.showEnd
            }));
            
            //FINE
        }
    }

    const endGame = (caller) => {
        //modificare il nome della funzione
        const self = firebaseCommands["endGame"];

        if (caller == firebaseCommands.creator) {
            /*
                MODIFICA INVIO COMANDO
            */
            pushValue(globalVars.code + "/commands", {
                commandName: self.name,     //nome comando
                sentBy: globalVars.type,    //inviato da display/bottone
                executeSelf: true           //deve eseguirlo anche chi ha inviato il comando
            }, true);

        } else {
            /*
                MODIFICA AZIONE COMANDO
            */
            //console.log("ENDGAME");

            goHome();
            
            //FINE
        }
    }

    const replay = (caller) => {
        //modificare il nome della funzione
        const self = firebaseCommands["replay"];

        if (caller == firebaseCommands.creator) {
            /*
                MODIFICA INVIO COMANDO
            */
            pushValue(globalVars.code + "/commands", {
                commandName: self.name,     //nome comando
                sentBy: globalVars.type,    //inviato da display/bottone
                executeSelf: true           //deve eseguirlo anche chi ha inviato il comando
            }, true);

        } else {
            /*
                MODIFICA AZIONE COMANDO
            */
            //console.log("REPLAY");

            if(globalVars.type == "b") {
                setValue(globalVars.code+"/status", 0);
            }

            //ritorna alla schermata di creazione delle squadre
            changeGame({
                enabled: false,
                cur: -1,          //standard +1, la prima sarà 0
                isLast: false
            });

            document.querySelector(".App").classList.remove("stage");

            wait_changeTeams({
                enabled: (globalVars.type == "d" ? true : false)
            });

            create_changeTeams({
                enabled: (globalVars.type == "b" ? true : false)
            });

            setIntro(true);
            setFadeOut(false);
            setCutScene(false);
            
            //FINE
        }
    }


    return (
        <div className='game'>
            {/* error dialog */}
            {error.show && <Dialog
                id="global_error"
                title= {error.title ? error.title : "Si è verificato un errore!"}
                txt={error.txt}
                width= {error.width ? error.width : "500px"}
                common={goHome}
            />}

            <SettingsDialog
                settings={settings}
                showSettings={showSettings}
                options={options}
            ></SettingsDialog>

            {/* loading dialog */}
            {loading && <LoadingDialog id="resumeLoading"/>}

            {/* SCENE VIDEO */}
            {showCutScene && globalVars.type == "d" && <CutScene intro={intro} onEnd={nextTeam} destroy={setCutScene} last={game.isLast} musicVolume={musicVolume} canSkip={canSkip}></CutScene>}
            {showCutScene && globalVars.type == "b" && <CutScene intro={intro} onEnd={fadeOut} destroy={setCutScene} last={isLastSkip} musicVolume={musicVolume} canSkip={canSkip}></CutScene>}
            

            {/* SCHERMATE */}
            {waitTeams.enabled && <WaitTeams listData={teams} changeState={setTeams} />}
            {createTeams.enabled && <CreateTeams
                listData={teams}
                changeState={setTeams}
                useStandards={useStandards}
                setUseStandards={setUseStandards}
                useCustoms={useCustoms}
                setUseCustoms={setUseCustoms}
                customWords={customWords}
                setCustomWords={setCustomWords}
                percent={percent}
                setPercent={setPercent}
            />}
            

            {/* BOTTONI SOTTO */}
            {!game.isLast && <div className='bottom' style={{marginTop: "5px"}}>
                <CommonButton   //GUIDE BUTTON
                    id="guide"
                    color={btnColors["orange"]}
                    txt={settingsIcon}
                    size={btnSizes["mini"]}
                    click={() => {
                        showSettings(true);
                    }}
                />
                <CommonButton   //FULLSCREEN BUTTON
                    id="fullscreen"
                    color={btnColors["blue"]}
                    txt={globalVars.fullscreen}
                    size={btnSizes["mini"]}
                    click={fullScreen}
                />
            </div>}

            {/* SCHERMATE DI GIOCO */}
            {game.enabled && globalVars.type == "d" && !game.isLast && <Game_display game={game}
                teams={teams}
                changeGame={changeGame}
                changeTeams={setTeams}
                words={words}
                doubleWords={doubleWords}
                customWords={customWords}
                customDoubleWords={customDoubleWords}
                readKeys={readKeys}
                setReadKeys={setReadKeys}
                readDoubleKeys={readDoubleKeys}
                setReadDoubleKeys={setReadDoubleKeys}
                readCustomKeys={readCustomKeys}
                setCustomReadKeys={setCustomReadKeys}
                readCustomDoubleKeys={readCustomDoubleKeys}
                setCustomReadDoubleKeys={setCustomReadDoubleKeys}
                forceStandards={forceStandards}
                forceDoubleStandards={forceDoubleStandards}
                musicVolume={musicVolume}
                effectsVolume={effectsVolume}
                percent={percent}
            ></Game_display>}
            {game.enabled && globalVars.type == "b" && !game.isLast && <Game_button game={game} changeGame={changeGame}></Game_button>}

            {/* RISULTATI PARZIALI*/}
            {game.enabled && globalVars.type == "b" && game.step == gameStatus_button.results && <div className='splash-screen' style={{zIndex: 1, flexFlow: 'row'}}>
                <Loading id={"resLoading"} color={loadingColors.white} speed={loadingSpeeds.slow} style={{marginRight: "20px"}}></Loading>
                <span className='title-txt'>Risultati...</span>
            </div>}

            {game.enabled && globalVars.type == "d" && game.step == gameStatus_display.showResults && <div className='results-bg fadein'>
                <Title
                    txt="Punteggio ottenuto"
                />

                <div className='step-body'>
                    <div className='teamNameContainer common-btn-orange' id='scoreAnim-container'>
                        <span className='common-btn-txt' style={{marginLeft: "30px"}}>{teams[game.cur].name}</span>
                        <div style={{flexGrow: 1}}></div>
                        <span className='common-btn-txt' id='animate-score' style={{marginRight: "50px"}}>0</span>
                    </div>

                    {/* LISTA IMPOSTATA CON JS */}
                    <div className='teamNameContainer placeAnim' id='placeAnim-1'>
                        <span className='place-txt' id='placeAnim-1-place' style={{marginLeft: "30px"}}></span>
                        <div style={{width: "20px"}}></div>
                        <span className='common-btn-txt' id='placeAnim-1-name'></span>
                        <div style={{flexGrow: 1}}></div>
                        <span className='common-btn-txt' id='placeAnim-1-score' style={{marginRight: "50px"}}></span>
                    </div>
                    <div className='teamNameContainer placeAnim' id='placeAnim-2'>
                        <span className='place-txt' id='placeAnim-2-place' style={{marginLeft: "30px"}}></span>
                        <div style={{width: "20px"}}></div>
                        <span className='common-btn-txt' id='placeAnim-2-name'></span>
                        <div style={{flexGrow: 1}}></div>
                        <span className='common-btn-txt' id='placeAnim-2-score' style={{marginRight: "50px"}}></span>
                    </div>
                    <div className='teamNameContainer placeAnim' id='placeAnim-3'>
                        <span className='place-txt' id='placeAnim-3-place' style={{marginLeft: "30px"}}></span>
                        <div style={{width: "20px"}}></div>
                        <span className='common-btn-txt' id='placeAnim-3-name'></span>
                        <div style={{flexGrow: 1}}></div>
                        <span className='common-btn-txt' id='placeAnim-3-score' style={{marginRight: "50px"}}></span>
                    </div>
                    <div className='teamNameContainer placeAnim' id='placeAnim-4'>
                        <span className='place-txt' id='placeAnim-4-place' style={{marginLeft: "30px"}}></span>
                        <div style={{width: "20px"}}></div>
                        <span className='common-btn-txt' id='placeAnim-4-name'></span>
                        <div style={{flexGrow: 1}}></div>
                        <span className='common-btn-txt' id='placeAnim-4-score' style={{marginRight: "50px"}}></span>
                    </div>
                </div>

            </div>}

            {globalVars.type == "d" && <AudioComponent ref={showScore_last} path={showScore_last_audio} volume={musicVolume}></AudioComponent>}

            {/* RISULTATI FINALI */}
            {game.isLast && globalVars.type == "d" && (game.step == gameStatus_display.lastResults || game.step == gameStatus_display.endGame) && <div className='results-bg fadein'>
                <div className='final-container' id="final-screen1">
                    <div className="mini-side mini-side-color-1" id='final-score'>
                        <span className='score-txt'>0</span>
                    </div>
                    
                    <div className={"teamNameContainer " + inputColors.done} id='final-team'>
                        <span className='game-word'>TEAM NAME</span>
                    </div>

                    <div className="turnTimer" id='final-position'>
                        <span className='turnTimer-text'>3°</span>
                    </div>
                </div>

                <div className='final-container' id="final-screen2">
                    <div className='final-half-container'>
                        <Title
                            txt="Risultati della partita"
                        />
                        <div style={{height: "50px"}}></div>
                        <List
                            id="final-list"
                            listData={teams_ordered}
                            fixed={true}
                            interactable={false}
                            type={listTypes.ranking}
                        ></List>
                        <div id='final-bottom-left'>
                            <img src={logo} alt="Logo" style={{height: "80px", marginLeft: "20px"}}/>
                        </div>
                    </div>
                    <div className='final-half-container'>
                        <div style={{height: "50px"}}></div>
                        <AppLogo></AppLogo>
                        <div style={{height: "200px"}}></div>

                        <CommonButton
                            id="replay"
                            txt="Gioca ancora!"
                            color={btnColors["orange"]}
                            size={btnSizes["default-wide"]}
                            click={() => {
                                firebaseCommands.replay.call(firebaseCommands.creator);
                            }}
                        />
                        <div style={{height: "15px"}}></div>
                        <CommonButton
                            id="goHome"
                            txt="Torna alla home"
                            color={btnColors["blue"]}
                            size={btnSizes["wide"]}
                            click={() => {
                                firebaseCommands.endGame.call(firebaseCommands.creator);
                            }}
                        />
                        <div style={{display: "flex", flexGrow: 1}}></div>
                        <div id='final-bottom-right'>
                            <CommonButton   //FULLSCREEN BUTTON
                                id="fullscreen"
                                color={btnColors["blue"]}
                                txt={globalVars.fullscreen}
                                size={btnSizes["mini"]}
                                click={fullScreen}
                            />
                        </div>
                    </div>
                </div>
            </div>}
            
        </div>
    );
}

export function listHasTeam(listaOggetti, nome) {
    return listaOggetti.some((oggetto) => oggetto.name === nome);
}
  

export default Game;