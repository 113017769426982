import React, { useEffect } from 'react';
import '../Game.css';
import { useState } from 'react';
import { addPathListener } from '../../../firebase';
import List from '../../../Objects/List/List';
import Title from '../../../Objects/Title/Title';

const WaitTeams = ({listData, changeState}) => {

    //prima volta, attiva listener per lista squadre
    useEffect(() => {
        let stop;
        listener();

        async function listener() {
            //console.log("1");
            stop = await addPathListener("teams", (teams) => {
                //console.log(teams)
                if(teams) {
                    changeState(teams)
                } else {
                    changeState([])
                }
            });
        }
        
        // Cleanup function: Disconnetti il listener quando il componente viene smontato
        return () => {
            if (stop) {
                stop(); // Disconnetti il listener
            }
        };
    }, []);

    return (
        <div className='waitTeams'>
            <Title txt="Competono le squadre:"/>
            <div className='waitTeams' style={{marginTop: 20+"px"}}>
                <List
                    id="teams"
                    listData={listData}
                    fixed={false}
                    interactable={false}
                />
            </div>
            
        </div>
    );
}

export default WaitTeams;